/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Login/actionTypes.js
 * @description action types for Login component
 */

export const GET_VALIDATION = 'GET_VALIDATION';
export const GET_TOKEN = 'GET_TOKEN';
