export const STATE = 'state';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(STATE);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(STATE, serializedState);
  } catch (error) {
    // Ignore error
  }
};

export const clearState = () => localStorage.clear();

export const getToken = () => {
  try {
    const { accessToken } = loadState();
    return accessToken;
  } catch (error) {
    return undefined;
  }
};

export const getLocale = () => {
  try {
    const { locale } = loadState();
    return locale;
  } catch (error) {
    return undefined;
  }
};
