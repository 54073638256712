/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Default/index.jsx
 * @description Default component
 */

/** Dependencies */
import React from 'react';
/** Components */
import Navbar from '../../Navbar';
import Loader from '../../Loader';
import Error from '../../Error';
/** Styles */
import './styles.scss';

const DefaultLayout = props => {
  const { children, history, setLocale, setError, loading, error, username } = props;
  return (
    <>
      <Navbar
        history={history}
        username={username}
        setLocale={setLocale}
      />
      <div className='container container-mt-4'>
        {children}
      </div>
      <Loader show={loading} />
      <Error show={error} setError={setError} />
    </>
  )
}

export default DefaultLayout;