/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Navbar/messages.js
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Navbar';

export default defineMessages({
  changeLocale: { id: `${parent}.changeLocale` },
  home: { id: `${parent}.home` },
  profile: { id: `${parent}.profile` },
  payments: { id: `${parent}.payments` },
  enviosExtractos: { id: `${parent}.enviosExtractos` },
  pazSalvo: { id: `${parent}.pazSalvo` },
  logout: { id: `${parent}.logout` },
});