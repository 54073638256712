/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Resend/view.js
 * @description Resend Container
 */

/** Dependencies */
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
/** Components */
import AuthLayout from '../../components/Layout/Auth';
import { PATH_AUTH_CONFIRM } from '../../config/Routes';
/** Messages */
import messages from './messages';
/** Styles */
import './styles.scss';

export class Resend extends Component {
  /**
   * React Lifecycle method, render Resend Container
   * @return { Component } Resend
   */
  render() {
    const { globalActions, loading, error } = this.props;
    const { setError } = globalActions;

    const {
      resendCodeTitle,
      resendCodeDescription,
      makeCallTitle,
      makeCallDescription
    } = messages;

    return (
      <AuthLayout
        loading={loading}
        error={error}
        setError={setError}
      >
        <div className='row'>
          <div className='col-6'>
            {this.button('fa-redo-alt', resendCodeTitle, resendCodeDescription, 'color-primary', () => this.resendCode())}
          </div>
          <div className='col-6'>
            {this.button('fa-phone-alt', makeCallTitle, makeCallDescription, 'color-secondary', () => this.makeCall(), true)}
          </div>
          <div className='col-12 mt-2 d-grid gap-2'>
            {this.backLogin()}
          </div>
        </div>
      </AuthLayout>
    );
  }

  /**
   * button
   * @param { string } icon 
   * @param { object } title 
   * @param { object } description 
   * @param { string } className 
   * @param { function } onClick 
   * @param { boolean } disabled
   */
  button(icon, title, description, className, onClick, disabled = false) {
    return (
      <div
        className={`d-flex flex-column justify-content-between px-2 py-4 text-center fake-button rounded-2 ${className}`}
        onClick={onClick}
        isdisabled={disabled ? `${disabled}` : undefined}
      >
        <i className={`fas ${icon} fa-2x`} />
        <strong className='my-2'>
          <FormattedMessage {...title} />
        </strong>
        <span>
          <FormattedMessage {...description} />
        </span>
      </div>
    );
  }

  backLogin() {
    const { history } = this.props;
    const { loginButton } = messages;
    
    return (
      <button
        className='btn btn-link'
        onClick={() => history.push(PATH_AUTH_CONFIRM)}
      >
        <FormattedMessage {...loginButton} />
      </button>
    );
  }

  resendCode() {
    const {
      actions: { reSend },
      document, phone,
    } = this.props;
    const payload = { document, phone };
    reSend(payload);
  }
  
  makeCall() {
    return false;
  }
}

export default injectIntl(Resend);

