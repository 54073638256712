/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Modal/index.jsx
 * @description Modal component
 */

/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

/** Constantas */
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }
};

export const Modal = props => {
  const { children, show, styles, contentLabel } = props;
  const modalStyles = { ...customStyles, ...styles };
  
  ReactModal.setAppElement('#root');

  return (
    <ReactModal
      isOpen={show}
      style={modalStyles}
      contentLabel={contentLabel}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  show: PropTypes.bool,
  styles: PropTypes.object,
  contentLabel: PropTypes.string,
};

Modal.defaultProps = {
  show: false,
  styles: {},
  contentLabel: 'react-modal'
};

export default Modal;