/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file PaymentsHome/index.js
 * @description PaymentsHome container
 */

/** Dependencies */
import React from "react";
import { Redirect } from "react-router-dom";
/** Constants */
import { PATH_PAYMENTS_FIND_NEXT } from "../../config/Routes";

const PaymentsHome = () => <Redirect to={PATH_PAYMENTS_FIND_NEXT} />;

export default PaymentsHome;
