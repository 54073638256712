/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Copyright/messages.js
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Copyright';

export default defineMessages({
  copyrightName: { id: `${parent}.copyrightName` },
  copyrightReserved: { id: `${parent}.copyrightReserved` },
});