/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Navbar/index.jsx
 * @description Navbar component
 */

/** Dependencies */
import React from 'react';
import { useIntl } from 'react-intl';
/** Messages */
import messages from './messages';
/** Constants */
import config from '../../environments';
import { PATH_AUTH_LOGIN, PATH_HOME } from '../../config/Routes';
import { LOCALES } from '../../utils/i18n/index';
/** Helpers */
import { clearState, getLocale } from '../../utils/commons/localstorage';
/** Styles */
import './style.scss';

const Navbar = ({ history, username, setLocale }) => {
  const { formatMessage } = useIntl();
  const { push } = history;

  const noop = () => false;

  const [ newLocale ] = LOCALES.filter(locale => locale !== getLocale());
  const lang = newLocale.toLocaleUpperCase();

  const logout = () => {
    clearState();
    setTimeout(() => push(PATH_AUTH_LOGIN), 200);
  };

  const renderLink = (link, index) => (
    <li key={`${link.label}-${index}`}>
      <button
        className='dropdown-item'
        {...link}
      >
        {link.label}
      </button>
    </li>
  );

  const links = [
    { label: formatMessage(messages.changeLocale, { lang }), onClick: () => setLocale(newLocale) },
    { label: formatMessage(messages.home), onClick: () => push(PATH_HOME) },
    { label: formatMessage(messages.profile), onClick: noop, hidden: true },
    { label: formatMessage(messages.payments), onClick: noop, hidden: true },
    { label: formatMessage(messages.enviosExtractos), onClick: noop, hidden: true },
    { label: formatMessage(messages.pazSalvo), onClick: noop, hidden: true },
    { label: formatMessage(messages.logout), onClick: logout },
  ];

  const responsiveMenu = (
    <>
      <div className='btn-group'>
        <button
          type='button'
          className={`btn ${config.navbar} dropdown-toggle`}
          data-bs-toggle='dropdown'
          data-bs-display='static'
          aria-expanded='false'
        >
          {username && (<span className='d-none d-sm-inline-block align-middle me-2 text-truncate navbar-truncate'>
            {username}
          </span>)}
          <i className='fas fa-user' />
        </button>
        <ul className='dropdown-menu dropdown-menu-end'>
          {username && <li className='d-inline-block d-sm-none'>
            <h6 className='dropdown-header text-truncate navbar-truncate'>
              {username}
            </h6>
          </li>}
          {links.map(renderLink)}
        </ul>
      </div>
    </>
  );

  return (
    <nav className={`navbar navbar-expand-lg navbar-dark ${config.navbar} fixed-top border-bottom`}>
      <div className='container-fluid'>
        <div className='navbar-brand pp-logotype'>
          <img
            className='d-inline-block align-text-top pp-logotype'
            alt='Logo prestapolis'
            src='/assets/images/logo-prestapolis-horizontal-blanco@2x.png'
          />
        </div>
        {responsiveMenu}
      </div>
    </nav>
  )
}

export default Navbar;