export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_DOCUMENT = 'SET_DOCUMENT';
export const SET_PHONE = 'SET_PHONE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_USERNAME = 'SET_USERNAME';
export const SET_CREDITS = 'SET_CREDITS';
export const SET_PAYMENT_PLAN = 'SET_PAYMENT_PLAN';
export const UNSET_SESSION = 'UNSET_SESSION';
