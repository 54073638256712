/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Loader/index.jsx
 * @description Loader component
 */

/** Dependencies */
import React from 'react';
/** Components */
import Modal from '../Modal';

const Loader = props => {
  const { show } = props;

  return (
    <Modal show={show} contentLabel='Loader'>
      <div
        className='spinner-border text-primary'
        role='status'
        style={{ width: '3rem', height: '3rem' }}
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
    </Modal>
  );
}

export default Loader;