/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Login/view.js
 * @description Login Container
 */

/** Dependencies */
import React, { Component } from "react";
import { injectIntl } from "react-intl";
/** Components */
import FormBuilder, {
  required,
  mustBeNumber,
  INPUT_TEXT,
  SUBMIT,
  BUTTON,
} from "../../components/Form";
import AuthLayout from "../../components/Layout/Auth";
/** Routes */
import {
  PATH_AUTH_CONFIRM,
  PATH_NOT_REGISTERED,
  PATH_PAYMENTS_SELECT_METHOD,
} from "../../config/Routes";
/** Helpers */
import { setMask } from "../../utils/commons/helpers";
/** Messages */
import messages from "./messages";
/** Constants */
import config from "../../environments";
import { WHATSAPP_NUMBER, ERROR_NOT_REGISTERED, ERROR_NOT_REGISTERED2 } from "./constants";
/** Styles*/
import "./styles.scss";

export class Login extends Component {
  /**
   * Initialize Login
   * @param { object } props - Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      localDocument: "",
    };

    this.getToken = this.getToken.bind(this);
    this.validate = this.validate.bind(this);
    this.setLocalDocument = this.setLocalDocument.bind(this);
    this.goToQuickPayment = this.goToQuickPayment.bind(this);
    this.goToNoRegistrer = this.goToNoRegistrer.bind(this);
    this.goToWhatsApp = this.goToWhatsApp.bind(this);
  }

  /**
   * React Lifecycle method, call when component is update
   * @param { object } prevProp - Preview component properties
   */
  componentDidUpdate(prevProps) {
    this.navigateToVerify();
  }

  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { globalActions, actions, loading } = this.props;
    const { setError } = globalActions;
    this.setMask();
    return (
      <AuthLayout loading={loading} setError={setError}>
        <FormBuilder
          onSubmit={this.validate({ ...globalActions, ...actions })}
          fields={this.fields()}
        />
        {this.buttonWhatsApp()}
      </AuthLayout>
    );
  }

  setLocalDocument(data) {
    if (data && data.target && data.target.value) {
      this.setState({ localDocument: data.target.value });
    }
  }

  /**
   * fields its a helper function to render fields in forms
   */
  fields() {
    const {
      intl: { formatMessage },
      loading,
    } = this.props;
    const { isLogin, localDocument } = this.state;

    const fields = [];

    fields.push({
      keyField: "document",
      type: INPUT_TEXT,
      validators: [required],
      placeholder: formatMessage(messages.personalIdentification),
      disabled: loading,
      pattern: "[0-9]*",
      inputmode: "tel",
      maxlength: 11,
      value: localDocument,
      onChange: this.setLocalDocument,
    });

    if (isLogin) {
      fields.push({
        keyField: "phone",
        type: INPUT_TEXT,
        validators: [required, mustBeNumber],
        placeholder: formatMessage(messages.numberPhone),
        disabled: loading,
        inputmode: "tel",
        maxlength: 10,
      });
    }

    if (config.env !== "prod") {
      fields.push({
        keyField: "submit",
        type: SUBMIT,
        label: formatMessage(messages.loginButton),
        className: "btn-primary",
        disabled: loading,
      });
    }

    if (!isLogin) {
      fields.push({
        keyField: "quickPayment",
        type: BUTTON,
        label: formatMessage(messages.quickPayment),
        className: "btn-secondary",
        disabled: loading,
        onClick: this.getToken,
      });
    }
    return fields;
  }

  goToWhatsApp() {
    window.location.replace(
      `https://api.whatsapp.com/send?phone=57%20${WHATSAPP_NUMBER}`
    );
  }

  /**
   * getToken
   */
  getToken() {
    const {
      actions: { getToken },
    } = this.props;
    const { localDocument } = this.state;
    getToken(localDocument, this.goToQuickPayment, this.goToNoRegistrer);
  }

  /**
   * goToQuickPayment
   */
  goToQuickPayment() {
    const { history } = this.props;
    const { localDocument } = this.state;
    const encodedDocument = btoa(localDocument);
    history.push(`${PATH_PAYMENTS_SELECT_METHOD}/${encodedDocument}`);
  }

  goToNoRegistrer(response) {
    const { history } = this.props;

    if (!response) {
      return history.push(PATH_NOT_REGISTERED);
    }

    const { codigoRespuesta, respuesta } = response;

    if (codigoRespuesta === ERROR_NOT_REGISTERED && !respuesta) {
      return history.push(PATH_NOT_REGISTERED);
    }
  }

  /**
   * buttonWhatsAPP
   */
  buttonWhatsApp() {
    return (
      <div className="col-12 col-md-8">
        <button
          type="button"
          className="btn btn-whatsapp"
          onClick={this.goToWhatsApp}
        >
          <i className="fab fa-whatsapp fa-2x" />
        </button>
      </div>
    );
  }

  /**
   * logIn its a helper function to submit form
   * @param { object } history - prop to navigate
   */
  validate = (actions) => (values) => {
    const { isLogin } = this.state;
    const { document } = values;
    const { getValidation } = actions;
    if (isLogin) {
      const formattedValues = {
        ...values,
        document: String(document).split(".").join(""),
      };
      getValidation(formattedValues, this.goToNoRegistrer);
    }
    if (!isLogin) {
      this.setState({ isLogin: !isLogin });
    }
  };

  /**
   * navigateToVerify its a helper function to redirect home page
   * @param { object } prevProps - Preview component properties
   */
  navigateToVerify() {
    const { document, phone, history } = this.props;
    if (!!document && !!phone) history.push(PATH_AUTH_CONFIRM);
  }

  /**
   * setMask
   */
  setMask() {
    setTimeout(() => setMask(), 100);
  }
}

export default injectIntl(Login);
