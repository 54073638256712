/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file LogoPrestapolis/index.jsx
 * @description LogoPrestapolis component
 */

/** Dependencies */
import React from 'react';
/** Styles */
import './styles.scss';


const LogoPrestapolis = () => (
  <img
    className='login-logo px-4 pb-4'
    alt='Logo prestapolis'
    
    src={"https://s3.amazonaws.com/cdn.prestapolis.com/logos/LOGO+FINAL_LOGO+CON+ISOTIPO.png"}
  />
);

export default LogoPrestapolis;