/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file SelectMethod/view.js
 * @description SelectMethod Container
 */

/** Dependencies */
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import AuthLayout from "../../components/Layout/Auth";
import { PATH_PAYMENTS_SELECT_METHOD } from "../../config/Routes";
/** Components */
import FormBuilder, { INPUT_TEXT } from "../../components/Form";
/** Helpers */
import {
  formatCurrency,
  dateFormatted,
  todayDiff,
  formatFiltered,
} from "../../utils/commons/helpers";
/** Messages */
import messages from "./messages";
import config from "../../environments";
/** Constants */
import {
  TYPE_MINIMUM,
  TYPE_OTHER,
  TYPE_TOTAL,
  WHATSAPP_NUMBER,
  QUOTA_CAP,
} from "./constants";

import "./styles.scss";

export class SelectMethod extends Component {
  /**
   * Initialize Login
   * @param { object } props - Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      creditData: null,
      creditId: null,
      paymentType: null,
      customValue: null,

    };

    this.renderCredit = this.renderCredit.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.setCustomValue = this.setCustomValue.bind(this);
    this.goToExternalPlatform = this.goToExternalPlatform.bind(this);
    this.goToCashPlatform = this.goToCashPlatform.bind(this);
    this.goToWhatsApp = this.goToWhatsApp.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.getData();
  }

  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { globalActions, loading } = this.props;
    const { setError } = globalActions;

    return (
      <AuthLayout loading={loading} setError={setError}>
        <div className="row mb-2 justify-content-center">
          {!loading && this.renderHeader()}
          {!loading && this.renderCredits()}
          {!loading && this.renderButtons()}
          {!loading && this.buttonBack()}
          {this.buttonWhatsApp()}
        </div>
      </AuthLayout>
    );
  }

  /**
   * renderHeader
   */
  renderHeader() {
    try {
      const {
        creditData: { respuesta, codigoRespuesta },
      } = this.state;

      if (codigoRespuesta === "001") {
        return (
          <div className="col-12 text-center">
            <span>
              Todos tus créditos se encuentra ya pagos. En caso de requerir el
              Paz y Salvo solicítalo a nuestro WhatsApp de servicio al cliente.
            </span>
          </div>
        );
      }
      if (codigoRespuesta === "002") {
        return (
          <div className="col-12 text-center">
            <span>
            El número documento consultado no ha tenido créditos con Prestápolis.
            </span>
          </div>
        );
      }

      const [{ documento, cupo }] = respuesta;
      const {
        datosPersona: { firstName },
      } = cupo;

      return (
        <>
          <div className="col-12 text-center">
            <span>
              <FormattedMessage {...messages.hi} />
            </span>
            <strong>{firstName}</strong>
          </div>

          <div className="col-12 text-center mb-4">
            <strong>{formatFiltered(documento)}</strong>
          </div>
        </>
      );
    } catch (error) {
      return <FormattedMessage {...messages.errorValues} />;
    }
  }

  /**
   * buttonBack
   */
  buttonBack() {
    return (
      <div className="col-12 col-md-8 mt-2 mx-auto text-center">
        <button
          type="button"
          style={{color:'#FFFFFF',
          background:'#2BAEB3',  border:'#2BAEB3'}}
          className="btn btn-primary me-1"
          onClick={this.goToback}
        >
          <i className="fas fa-arrow-left me-2" />
          Volver
        </button>
      </div>
    );
  }

  goToback() {
    window.history.go(-1);
  }

  /**
   * buttonWhatsAPP
   */
  buttonWhatsApp() {
    return (
      <div className="col-12 col-md-8">
        <button
          type="button"
          className="btn btn-whatsapp2"
          onClick={this.goToWhatsApp}
        >
          <i className="fab fa-whatsapp fa-2x" />
        </button>
      </div>
    );
  }

  goToWhatsApp() {
    window.location.replace(
      `https://api.whatsapp.com/send?phone=57%20${WHATSAPP_NUMBER}`
    );
  }

  renderButtons() {
    const { creditData } = this.state;

    if (creditData && creditData.respuesta && !creditData.respuesta.length) {
      return null;
    }

    return (
      <>
        <div className="col-9 text-center mb-2">
          <strong>
            <FormattedMessage {...messages.payWith} />
          </strong>
        </div>
        <div className="col-9 mb-2">
          {this.button(
            messages.methodCash,
            "fa-money-bill-alt",
            messages.subMethodCash,
            "color-secondary",
            () => {
              this.goToMethod();
            }
          )}
        </div>
        <div className="col-9 mb-2"  >
          {this.button(messages.methodPSE, 1,  messages.subMehodPSE, "color-primary", () =>
            this.goToMethod("pse")
          )}
        </div>
      </>
    );
  }

  /**
   * renderOption
   * @returns
   */

  renderOption(id, totalPayment, minimumPayment, type, label) {
    const { creditId, paymentType } = this.state;

    const isThisCredit = id === creditId;

    const isTextValue = type === TYPE_MINIMUM || type === TYPE_TOTAL;

    let numericValue;
    let renderValue;

    if (type === TYPE_OTHER) {
      const props = {
        onSubmit: () => false,
        fields: [
          {
            keyField: "payment",
            type: INPUT_TEXT,
            validators: [],
            className: "text-end fw-bold option-input",
            placeholder: "$",
            disabled: !isThisCredit || paymentType !== TYPE_OTHER,
            pattern: "[0-9]*",
            inputmode: "tel",
            currency: true,
            prefix: "$",
            onChange: this.setCustomValue,
          },
        ],
      };
      renderValue = <FormBuilder {...props} />;
    }

    if (type === TYPE_MINIMUM) numericValue = Math.round(minimumPayment);
    if (type === TYPE_TOTAL) numericValue = Math.round(totalPayment);

    if (isTextValue) {
      renderValue = <strong>${formatCurrency(Number(numericValue))}</strong>;
    }

    const newState = { creditId: id, paymentType: type, customValue: null };
    const onRadio = () => this.setState(newState);

    const checkbox = (
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          checked={isThisCredit && paymentType === type}
          onChange={onRadio}
        />
        <label className="form-check-label">{label}</label>
      </div>
    );

    if (isTextValue) {
      return (
        <div className="col-12 mb-2 d-flex justify-content-between">
          {checkbox}
          {renderValue}
        </div>
      );
    }

    return (
      <>
        <div className="col-12 d-flex justify-content-start">{checkbox}</div>
        <div className={"col-12 mb-2 d-flex justify-content-end"}>
          {renderValue}
        </div>
      </>
    );
  }

  /**
   * renderCredit
   * @param { object } element
   * @param { number } index
   */
  renderCredit(element, index) {
    const { id, gastoAcumulados, valorCuota, cuotas, cuotaspagadas,primerPago,saldoCostoPlataforma,vsf, saldoCapital } = element;
    const [{ pago_minimo_cliente, pagoTotal, valorPagado, saldoMora, interesCorrienteAcum,  gastoCobranzaAcum, interesMoraAcum, fgaAcumulado, costoAcumulado}] = gastoAcumulados;

    const dateDiff = this.feeInDelay(element.fechaproximopago);
    const renderLimitDate = dateDiff ? (
      <FormattedMessage {...messages.immediatePayment} />
    ) : (
      dateFormatted(element.fechaproximopago, "DD-MM-YYYY")
    );

    let totalPayment = saldoCapital + saldoCostoPlataforma + vsf;
    let minimumPayment = valorCuota; 

    if (gastoAcumulados.length > 0) {
     const pagoMinimoCalc = (() => {
      if (
        Math.abs(cuotas - cuotaspagadas) <= 1 && (valorPagado + primerPago) < valorCuota*cuotas && pagoTotal<valorCuota
      ) {
        return valorCuota;
      } else if (
        Math.abs(cuotas - cuotaspagadas) <= 1
      ) {
        return pagoTotal + saldoCostoPlataforma + vsf;
      } else if (
        pago_minimo_cliente < 5000 || pago_minimo_cliente < valorCuota
      ) {
        return valorCuota;
      } else if (
        (saldoMora+interesCorrienteAcum+gastoCobranzaAcum+interesMoraAcum+fgaAcumulado+costoAcumulado) > pago_minimo_cliente
      ) {
        return saldoMora+interesCorrienteAcum+gastoCobranzaAcum+interesMoraAcum+fgaAcumulado+costoAcumulado;
      } else {
        return pago_minimo_cliente;
      }
    })();
   
    const pagoTotalCalc = (() => {
      if (
        Math.abs(cuotas - cuotaspagadas) <= 1 && (valorPagado + primerPago) < valorCuota*cuotas && pagoTotal<valorCuota
      ) {
        return valorCuota;
      } else {
        return pagoTotal + saldoCostoPlataforma + vsf;
      }
    })();

    totalPayment = pagoTotalCalc;
    minimumPayment = pagoMinimoCalc; 
  }
    if((totalPayment - minimumPayment) < QUOTA_CAP) minimumPayment = totalPayment;
    
    let content = (
      <div className="col-12 mb-1 text-center">
        Todos tus créditos se encuentra ya pagos. En caso de requerir el Paz y
        Salvo solicítalo a nuestro WhatsApp de servicio al cliente.
      </div>
    );

    if (minimumPayment !== 0 || totalPayment !== 0) {
      content = (
        <>
          <div className="col-12 mb-1 d-flex justify-content-between">
            <span>
              <FormattedMessage {...messages.nextPaymentDate} />
            </span>
            <strong>{renderLimitDate}</strong>
          </div>
          <div className="col-12 mb-1 d-flex justify-content-between">
          <span>
              <FormattedMessage {...messages.feesPaid} />
            </span>
            
            <strong>{cuotaspagadas == cuotas ? cuotas -1 : cuotaspagadas}/{cuotas}</strong>        
          </div>

          <div className="col-12 text-center my-4">
            <strong>
              <FormattedMessage {...messages.amountPay} />
            </strong>
          </div>

          {this.renderOption(
            id,
            totalPayment,
            minimumPayment,
            TYPE_MINIMUM,
            "Pago minimo:"
          )}
          {this.renderOption(
            id,
            totalPayment,
            minimumPayment,
            TYPE_TOTAL,
            "Pago total:"
          )}
          {this.renderOption(
            id,
            totalPayment,
            minimumPayment,
            TYPE_OTHER,
            "Otro valor:"
          )}
        </>
      );
    }

    return (
      <div key={`${id}-${index}`} className="row my-1 p-2 border">
        {content}
      </div>
    );
  }

  /**
   * renderCredits
   * @returns renderCredits
   */
  renderCredits() {
    try {
      const { creditData } = this.state;

      if (!creditData || !creditData.respuesta) return null;

      const { respuesta } = creditData;

      return respuesta.map(this.renderCredit);
    } catch (error) {
      return <FormattedMessage {...messages.errorValues} />;
    }
  }

  /**
   * button
   * @param { string } icon
   * @param { objectstring } title
   * @param { objectstring } description
   * @param { string } className
   * @param { function } onClick
   * @param { boolean } disabled
   */
  button(title, icon, subtitle, className, onClick) {
    let disabled = false;
    try {
      const {
        creditData: { respuesta },
        creditId,
        paymentType,
        customValue,
      } = this.state;

      if (paymentType !== TYPE_OTHER) throw new Error(TYPE_OTHER);

      const [first] = respuesta.filter(({ id }) => id === creditId);
      const { gastoAcumulados, saldoCapital,saldoCostoPlataforma,vsf } = first;

      let totalPayment = saldoCapital+saldoCostoPlataforma+vsf;

      if (gastoAcumulados.length > 0) {
        const [{ pagoTotal }] = gastoAcumulados;
        totalPayment = pagoTotal+saldoCostoPlataforma+vsf;
      }

      const total = totalPayment;
      disabled = total < customValue;
    } catch (error) {
      disabled = false;
    }

    const renderIcon =
      typeof icon === "string" ? (
        <i className={`fas ${icon} fa-2x my-auto me-2`} />
      ) : (
        <img
          src="/assets/images/pse-white.png"
          className="me-2"
          alt="pse"
          style={{ width: "32px" }}
        />
      );
    return (
      <div
        className={`d-flex flex-column p-2 text-center fake-button rounded-2 ${className}`}
        onClick={disabled ? () => false : onClick}
        isdisabled={disabled ? `${disabled}` : undefined}
      >
        <div className="d-flex flex-row justify-content-center align-items-center mb-2">
          {renderIcon}
          <strong>
            <FormattedMessage {...title} />
          </strong>
        </div>
        <span className="sub-texto">
          <FormattedMessage {...subtitle}/>
        </span>
      </div>
    );
  }

  /**
   * setCustomValue
   * @param { object } data
   */
  setCustomValue(data) {
    try {
      const { paymentType } = this.state;

      if (paymentType !== TYPE_OTHER) return;

      this.setState({ customValue: data.value });
    } catch (error) {}
  }

  /**
   * goToNextPayment
   * @param { object } values - form builder values
   */
  goToMethod(method) {
    const {
      actions: { createOrder },
    } = this.props;
    try {
      const {
        creditData: { respuesta },
        creditId,
        paymentType,
        customValue,
      } = this.state;

      const [first] = respuesta.filter(({ id }) => id === creditId);
      const { cupo, documento, gastoAcumulados, valorCuota,cuotas,cuotaspagadas,primerPago,saldoCapital, saldoCostoPlataforma,vsf } = first;
      const { datosPersona } = cupo;
      const { contactos, nombrecompleto } = datosPersona;
      const [{ email }] = contactos;
      const [{ valorPagado,pago_minimo_cliente,pagoTotal,saldoMora, interesCorrienteAcum,  gastoCobranzaAcum, interesMoraAcum, fgaAcumulado, costoAcumulado}] = gastoAcumulados;

      let totalPayment = saldoCapital + saldoCostoPlataforma + vsf;
      let minimumPayment = valorCuota; 

      if (gastoAcumulados.length > 0) {
       const pagoMinimoCalc = (() => {
        if (
          Math.abs(cuotas - cuotaspagadas) <= 1 && (valorPagado + primerPago) < valorCuota*cuotas && pagoTotal<valorCuota
        ) {
          return valorCuota;
        } else if (
          Math.abs(cuotas - cuotaspagadas) <= 1 
        ) {
          return pagoTotal + saldoCostoPlataforma + vsf;
        } else if (
          pago_minimo_cliente < 5000 || pago_minimo_cliente < valorCuota
        ) {
          return valorCuota;
        } else if (
          saldoMora+interesCorrienteAcum+gastoCobranzaAcum+interesMoraAcum+fgaAcumulado+costoAcumulado > pago_minimo_cliente
        ) {
          return saldoMora+interesCorrienteAcum+gastoCobranzaAcum+interesMoraAcum+fgaAcumulado+costoAcumulado;
        } else {
          return pago_minimo_cliente;
        }
      })();
     
      const pagoTotalCalc = (() => {
        if (
          (cuotas - cuotaspagadas) <= 1 && (valorPagado + primerPago) < valorCuota*cuotas && pagoTotal<valorCuota
        ) {
          return valorCuota;
        } else {
          return pagoTotal + saldoCostoPlataforma + vsf;
        }
      })();

      totalPayment = pagoTotalCalc;
      minimumPayment = pagoMinimoCalc;
    }
      if((totalPayment - minimumPayment) < QUOTA_CAP) minimumPayment = totalPayment;
      let amount = customValue;

      if (paymentType === TYPE_TOTAL) amount = String(totalPayment);
      if (paymentType === TYPE_MINIMUM) amount = String(minimumPayment);

      const currentDate = dateFormatted(new Date(), "DD MM YYYY");
      const description = `credito ${creditId} fecha ${currentDate}`;
      const data = {
        email,
        country: 343,
        money: "COP",
        amount,
        description,
        iva: "0",
        user_di: formatFiltered(documento),
        user_type_di: "CC",
        user_name: nombrecompleto,
      };

      let callback = this.goToCashPlatform;
      if (method) {
        data.method = method;
        callback = this.goToExternalPlatform;
      }

      createOrder(data, callback);
    } catch (error) {}
  }

  /**
   * getQueryParams
   */
  getQueryParams() {
    const {
      location: { search },
    } = this.props;
    const queryParams = {};
    search
      .replace(/\?/, "")
      .split("&")
      .forEach((queryParam) => {
        const [key, value] = queryParam.split("=");
        queryParams[key] = value;
      });
    return queryParams;
  }

  /**
   * getEncodedValues
   */
  getEncodedValues() {
    const {
      match: {
        params: { document: encodedUuid },
      },
    } = this.props;
    const document = atob(encodedUuid);
    return { document };
  }

  /**
   * getData
   */
  getData() {
    const {
      actions: { getData },
    } = this.props;
    const { document } = this.getEncodedValues();
    getData(document, (data) => this.setState({ creditData: data }));
  }

  /**
   * feeInDelay
   */
  feeInDelay(dateLimit) {
    const dateDiff = todayDiff(dateLimit);
    return dateDiff < 0;
  }

  goToCashPlatform(response) {
    const {
      match: {
        params: { document: encodedUuid },
      },
      history: { push },
    } = this.props;
    try {
      const { respuesta } = response;
      const { DATA } = respuesta;
      const { Monto, Referencia } = DATA;
      push(
        `${PATH_PAYMENTS_SELECT_METHOD}/cash/${encodedUuid}?payment=${Monto}&reference=${Referencia}&ord=${DATA.OrdenID}`
      );
    } catch (error) {}
  }

  /**
   * goToExternalPlatform
   * @param { object } response
   */
  goToExternalPlatform(response) {
    try {
      const { respuesta } = response;
      const { DATA } = respuesta;
      const { checkout } = DATA;
      window.location.replace(`https://${checkout}`);
    } catch (error) {}
  }
}

export default injectIntl(SelectMethod);
