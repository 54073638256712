/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Error/index.jsx
 * @description Error component
 */

/** Dependencies */
import React from 'react';
import { FormattedMessage } from 'react-intl';
/** Components */
import Modal from '../Modal';
/** Messages */
import messages from './messages';

const Error = props => {
  const { show, setError } = props;

  return (
    <Modal
      show={Boolean(show)}
      contentLabel='Error'
    >
      <div className='d-flex flex-column justify-content-center'>
        <span>
          <FormattedMessage {...messages.error} />
        </span>

        <button
          className='btn btn-primary mt-2'
          onClick={() => setError(undefined)}
        >
          <FormattedMessage {...messages.ok} />
        </button>
      </div>
    </Modal>
  );
}

export default Error;