/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Login/saga.js
 */

/** Dependencies */
import { takeLatest } from 'redux-saga/effects';
/** Services */
import * as authService from '../../../services/auth';
/** Constants */
import { GET_VALIDATION, GET_TOKEN } from './actionTypes';

export default function* loginSaga() {
  yield takeLatest(GET_VALIDATION, authService.validate);
  yield takeLatest(GET_TOKEN, authService.getToken);
}