/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file LanguageProvider/view.js
 * @description LanguageProvider Container
 */

/** Dependencies */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
/** Redux */
import * as globalActions from '../../store/actions';

/**
 * Get Actions to components
 * @param { function } dispatch - Run action to execute in redux
 */
 export const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(globalActions, dispatch),
});

/**
 * Get props to components
 * @param { function } state - Redux state
 */
const mapStateToProps = state => ({
  locale: state.locale,
});

/**
 * Prepare actions and data to Redux
 */
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export class LanguageProvider extends PureComponent {
  /**
   * React Lifecycle method, render LanguageProvider Container
   * @return { Component } LanguageProvider
   */
  render() {
    const { children, locale, messages } = this.props;

    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={messages[locale]}
      >
        {React.Children.only(children)}
      </IntlProvider>
    );
  }
}

/** Proptypes allowed */
LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired,
};

export default compose(withConnect)(LanguageProvider);