/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file FindNext/view.js
 * @description FindNext Container
 */

/** Dependencies */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { v4 } from 'node-uuid';
/** Components */
import FormBuilder, {
  required,
  mustBeNumber,
  INPUT_TEXT,
  SUBMIT,
} from '../../components/Form';
import AuthLayout from '../../components/Layout/Auth';
/** Messages */
import messages from './messages';
/** Routes */
import { PATH_PAYMENTS_SELECT_METHOD } from '../../config/Routes';

export class FindNext extends Component {
  /**
   * Initialize FindNext
   * @param { object } props - Component properties
   */
  constructor(props) {
    super(props);

    this.goToNextPayment = this.goToNextPayment.bind(this);
  }

  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { globalActions, loading } = this.props;
    const { setError } = globalActions;

    return (
      <AuthLayout
        loading={loading}
        setError={setError}
      >
        <FormBuilder
          onSubmit={this.goToNextPayment}
          fields={this.fields()}
        />
      </AuthLayout>
    );
  }

  /**
   * fields its a helper function to render fields in forms
   */
  fields() {
    const {
      intl: { formatMessage },
      loading,
    } = this.props;

    return [
      {
        keyField: 'document',
        type: INPUT_TEXT,
        validators: [required, mustBeNumber],
        placeholder: formatMessage(messages.documentPayer),
        disabled: loading,
        pattern: '[0-9]*',
        inputmode: 'tel',
        maxlength: 11,
      },
      {
        keyField: 'paymentValue',
        type: INPUT_TEXT,
        validators: [required],
        placeholder: formatMessage(messages.amountPay),
        disabled: loading,
        pattern: '[0-9]*',
        inputmode: 'tel',
        maxlength: 11,
        className: 'fw-bold fs-5',
        currency: true,
        prefix: '$',
      },
      {
        keyField: 'submit',
        type: SUBMIT,
        label: formatMessage(messages.selectMethodButton),
        className: 'btn-primary',
        disabled: loading
      },
    ];
  }

  /**
   * goToNextPayment
   * @param { object } values - form builder values
   */
  goToNextPayment({ document, paymentValue }) {
    const { history } = this.props;
    const encodedDocument = btoa(document);
    const [firstUuid, secondUuid] = v4().split('-');

    const payment = Number(paymentValue.replace(/\$/, '').replace(/\./, ''));
    const encodedPayment = btoa(payment);

    const redirect = `${encodedDocument}-${firstUuid}`;
    history.push(`${PATH_PAYMENTS_SELECT_METHOD}/${redirect}?payment=${encodedPayment}-${secondUuid}`);
  }
}

export default injectIntl(FindNext);
