/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file MethodCash/view.js
 * @description MethodCash Container
 */

/** Dependencies */
import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import html2canvas from "html2canvas";
import moment from "moment";
/** Components */
import AnonymousLayout from "../../components/Layout/Anonymous";
import HeadersPayment from "../../components/HeadersPayment";
/** Helpers */
import { formatCurrency, dateFormatted } from "../../utils/commons/helpers";
/** Messages */
import messages from "./messages";
/** Constants */
import { TABS, DAYS_ADDITIONAL, IS_ENABLED } from "./constants";
/** Styles */
import "./styles.scss";



export class MethodCash extends Component {
  /**
   * Initialize CreditDetail
   * @param { object } props - Component properties
   */
  constructor(props) {
    super(props);

    const tabs = TABS.map((tab, index) => ({ ...tab, show: !index }));
    const fullDate = moment().add(DAYS_ADDITIONAL, 'days');
    const [year, month, day] = dateFormatted(fullDate, "YYYY MM DD").split(" ");

    this.state = {
      tabs,
      fullDate,
      year,
      month,
      day,
    };

    this.takeScreenshot = this.takeScreenshot.bind(this);
    this.shareUrl = this.shareUrl.bind(this);
  }

  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { globalActions, loading } = this.props;
    const { setError } = globalActions;

    return (
      <AnonymousLayout loading={loading} setError={setError}>
        <div className="row py-3">
          {this.renderPayment()}
          {this.renderParnerts()}
          {this.renderHelp()}
          {this.renderButtons()}
          {this.renderButtons2()}
        </div>
      </AnonymousLayout>
    );
  }

  /**
   * renderPayment
   * @returns { Component }
   */
  renderPayment() {
    const headerValues = [
      this.renderLimitDate(),
      this.renderTotalPayment(),
      //this.renderReference(),
    ];

    return <HeadersPayment values={headerValues} />;
  }

  /**
   * renderParnerts
   * @returns { Component }
   */
  renderParnerts() {
    return (
      <>
        <div className="col-12 text-center mx-auto mt-4">
          <strong>
            <FormattedMessage {...messages.agreementCode} />
          </strong>
        </div>

        <div className="col-12 col-md-12 mt-2 mx-auto">{this.renderTabs()}</div>

      </>
    );
  }

  renderHelp() {
    const {
      intl: { formatMessage },
    } = this.props;
    try {
      console.log(formatMessage(messages.help0));
      return (
        <div className="col-12 col-md-8 mt-2 mx-auto text-center">
          <p
            dangerouslySetInnerHTML={{ __html: formatMessage(messages.help0) }}
          />
          <p
            dangerouslySetInnerHTML={{ __html: formatMessage(messages.help1) }}
          />
        </div>
      );
    } catch (error) { }
  }

  /**
   * renderButtons
   */
  renderButtons() {
    const shareButton = IS_ENABLED ? (
      <button type="button" className="btn btn-primary" onClick={this.shareUrl}>
        Compartir
        <i className="fas fa-share ms-2" />
      </button>
    ) : null;

    return (
      <div className="col-12 col-md-8 mt-2 mx-auto text-center">
        <button
          type="button"
          className="btn btn-primary me-1"
          onClick={this.takeScreenshot}
        >
          Guardar
          <i className="fas fa-copy ms-2" />
        </button>

        {shareButton}
      </div>
    );
  }

  /**
   * renderButtons2
   */
  renderButtons2() {
    return (
      <div className="col-12 col-md-8 mt-2 mx-auto text-center">
        <button
          type="button"
          className="btn btn-primary me-1"
          onClick={this.goToback}
        >
          Volver
          <i className="fas fa-arrow-left ms-2" />
        </button>
      </div>
    );
  }

  goToback() {
    window.history.go(-1);
  }

  /**
   * renderTabs
   */
  renderTabs() {
    const { tabs } = this.state;

    const idParent = "agreements";

    return (
      <div className="margin py-0" id={idParent}>
        {tabs.map((tab) => this.agreementGeneral(tab))}
      </div>

    );
  }

  /**
   * renderLimitDate
   */
  renderLimitDate() {
    const value = this.getLimitDate();

    return { label: messages.dateLimit, value };
  }

  /**
   * renderTotalPayment
   */
  renderTotalPayment() {
    const value = this.getTotalPayment();

    return { label: messages.totalPayment, value };
  }

  /**
   * renderReference
   */
  renderReference() {
    const value = this.getReference();

    return { label: messages.refCode, value };
  }

  /**
   * agreementGeneral
   */
  agreementGeneral({ label, code, image, numberRef }) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div class="card text-center" key={`${label}-0`}>
          <img class="card-img-top" src={image} style={{ width: '100%' }} alt="Card image" />
          <div class="card-body justify-content-center align-items-center">
            <h4 class="card-title">Convenio: {code}</h4>
            <p class="card-text"><strong className="fs-4 text-primary">Referencia: {numberRef ? this.getReference() : "265" + this.getOrder()}</strong></p>
          </div>
        </div>
      </div>
      // <div className="row" key={`${label}-0`}>
      //   <div className="col-6 col-span-2 -mb-1 d-flex justify-content-center align-items-center">
      //       <img className='image-brand' src={image} />
      //   </div>

      //   <div className="col-6 -mb-1 justify-content-center align-items-center">
      //     <div className="row">
      //       <strong className="fs-4 text-primary">Convenio: {code}</strong>
      //     </div>
      //     <div className="row">
      //       <strong className="fs-4 text-primary">Referencia: {numberRef? this.getReference():"265"+this.getOrder()}</strong>
      //     </div>

      //   </div>
      // </div>
    );
  }

  /**
   * getLimitDate
   */
  getLimitDate() {
    const { year, month, day } = this.state;
    const value = `${day}/${month}/${year}`;

    return value;
  }

  /**
   * getTotalPayment
   */
  getTotalPayment() {
    const { payment } = this.getQueryParams();
    const value = formatCurrency(Number(payment));

    return `$${value}`;
  }

  /**
   * getReference
   */
  getReference() {
    const { reference } = this.getQueryParams();

    return reference;
  }

  /**
   * getReference
   */
  getOrder() {
    const { ord } = this.getQueryParams();

    let cc = ord.split("P");

    return cc[1];
  }

  /**
   * takeScreenshot
   */
  async takeScreenshot() {
    const { document: userDocument } = this.getEncodedValues();

    const screenshotTarget = document.body;
    const noscript = document.getElementsByTagName("noscript").item(0);
    noscript.style.display = "none";
    const canvas = await html2canvas(screenshotTarget);
    await canvas.toBlob((blob) => {
      const downloader = document.createElement("a");
      downloader.style.display = "none";
      const url = URL.createObjectURL(blob);
      downloader.href = url;
      downloader.download = `prestapolis-${userDocument}.png`;
      downloader.click();
      window.URL.revokeObjectURL(url);
      downloader.remove();
    });
    noscript.style.display = "unset";
  }

  /**
   * shareUrl
   */
  async shareUrl() {
    if (!navigator.canShare) return;

    const {
      intl: { formatMessage },
    } = this.props;
    const { tabs } = this.state;
    const {
      location: { href: currentLocation },
    } = window;

    const methodCashDesc = formatMessage(messages.methodCashDesc);
    const dateLimit = formatMessage(messages.dateLimit);
    const refCode = formatMessage(messages.refCode);
    const totalPayment = formatMessage(messages.totalPayment);
    const agreementCode = formatMessage(messages.agreementCode);

    await navigator.share({
      title: `Prestapolis - ${methodCashDesc}`,
      text: [
        `${methodCashDesc}`,
        `${dateLimit} ${this.getLimitDate()}`,
        `${refCode} ${this.getReference()}`,
        `${totalPayment} ${this.getTotalPayment()}`,
        `${agreementCode}`,
        `${tabs
          .map(({ label, code }) => `${label.toLowerCase()}: ${code}`)
          .join("\n")}`,
      ].join("\n"),
      url: currentLocation,
    });
  }

  /**
   * getEncodedValues
   */
  getEncodedValues() {
    const {
      match: {
        params: { document: encodedUuid },
      },
    } = this.props;
    const [encodedDocument] = encodedUuid.split("-");
    const document = atob(encodedDocument);
    return { document };
  }

  /**
   * getQueryParams
   */
  getQueryParams() {
    const {
      location: { search },
    } = this.props;
    const queryParams = {};
    search
      .replace(/\?/, "")
      .split("&")
      .forEach((queryParam) => {
        const [key, value] = queryParam.split("=");
        queryParams[key] = value;
      });
    return queryParams;
  }
}

export default injectIntl(MethodCash);
