/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file SelectMethod/messages.js
 * @description SelectMethod component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';
/** Other messages */
import feeMessages from '../../components/Fee/messages';
import homeMessages from '../../containers/Home/messages'
import findNextMessages from '../FindNext/messages';

const parent = 'SelectMethod';

export default defineMessages({
  payWith: { id: `${parent}.payWith` },
  methodCash: { id: `${parent}.methodCash` },
  subMethodCash: { id: `${parent}.subMethodCash` },
  methodPSE: { id: `${parent}.methodPSE` },
  subMehodPSE: { id: `${parent}.subMehodPSE` },
  methodTC: { id: `${parent}.methodTC` },
  methodCashDesc: { id: `${parent}.methodCashDesc` },
  methodPseDesc: { id: `${parent}.methodPseDesc` },
  methodTcDesc: { id: `${parent}.methodTcDesc` },
  errorValues: { id: `${parent}.errorValues` },
  documentPayer: { id: `${parent}.documentPayer` },
  credit: { id: `${parent}.credit` },
  nextPaymentDate: { id: `${parent}.nextPaymentDate` },
  hi: { id: `${parent}.hi` },
  paymentValue: { ...feeMessages.paymentValue },
  amountPay: { ...findNextMessages.amountPay },
  immediatePayment: { ...feeMessages.immediatePayment },
  feesPaid: { ...homeMessages.feesPaid },
});
