/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Form/index.jsx
 * @description Form component
 */

/** Dependencies */
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
/** Form components */
import Input from './elements/Input';
import Button from './elements/Button';
import Select from './elements/Select';
/** Form config */
import {
  minValue,
  mustBeNumber,
  required,
  INPUT_TEXT,
  INPUT_EMAIL,
  INPUT_PASSWORD,
  INPUT_NUMBER,
  INPUT_DATE,
  SELECT,
  BUTTON,
  SUBMIT,
} from './config';

export class FormBuilder extends Component {
  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { onSubmit, fields } = this.props;
    const initialValues = this.initialValues();

    return (
      <Form
        onSubmit={onSubmit}
        render={this.renderForm(fields)}
        initialValues={initialValues}
      />
    );
  }

  /**
   * renderField is a helper function to render field
   * @param { object } field - object with data to render field
   */
  renderField = formProps => field => {
    const { handleSubmit } = formProps;
    const { keyField, type } = field;

    if (
      [
        INPUT_TEXT,
        INPUT_EMAIL,
        INPUT_PASSWORD,
        INPUT_NUMBER,
        INPUT_DATE,
      ].includes(type)
    ) {
      return <Input key={keyField} {...field} />;
    }

    if (SELECT === type) {
      return <Select key={keyField} {...field} />;
    }

    if (BUTTON === type) {
      return <Button key={keyField} {...field} />;
    }

    if (SUBMIT === type) {
      return <Button key={keyField} {...field} onClick={handleSubmit} />;
    }

    return null;
  };

  /**
   * renderForm is a helper function to render a field
   * @param { object } formProps
   */
  renderForm = fields => formProps => {
    const { handleSubmit } = formProps;

    return (
      <form onSubmit={handleSubmit}>
        {fields.map(this.renderField(formProps))}
      </form>
    );
  };

  /**
   * initialValues
   */
  initialValues() {
    const { fields } = this.props;
    const values = fields
      .filter(field => !!field.value)
      .map(({ keyField, value }) => `"${keyField}":"${value}"`)
      .join(',');

    if (values.trim().length === 0) return {};

    return JSON.parse(`{${values}}`);
  }
}

FormBuilder.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export {
  minValue,
  mustBeNumber,
  required,
  INPUT_TEXT,
  INPUT_EMAIL,
  INPUT_PASSWORD,
  INPUT_NUMBER,
  INPUT_DATE,
  SELECT,
  BUTTON,
  SUBMIT,
};

export default FormBuilder;
