/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file App/index.jsx
 * @description App component
 */

/** Dependencies */
import React from 'react';
/** Routes */
import Routes from '../../config/Routes';

const App = props => <Routes {...props} />;

export default App;