/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Fee/messages.js
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Fee';

export default defineMessages({
  selectAll: { id: `${parent}.selectAll` },
  numberFee: { id: `${parent}.numberFee` },
  dateLimit: { id: `${parent}.dateLimit` },
  balance: { id: `${parent}.balance` },
  datePayment: { id: `${parent}.datePayment` },
  paymentValue: { id: `${parent}.paymentValue` },
  statusFeeId: { id: `${parent}.statusFeeId` },
  daysDelay: { id: `${parent}.daysDelay` },
  platformCost: { id: `${parent}.platformCost` },
  feeBalance: { id: `${parent}.feeBalance` },
  feePaid: { id: `${parent}.feePaid` },
  feeValue: { id: `${parent}.feeValue` },
  immediatePayment: { id: `${parent}.immediatePayment` },
  feesPaid: { id: `${parent}.feesPaid` },
});