export const config = {
  env: "prod",
  baseUrl: "https://authentication.prestapolis.com",
  navbar: "pp-bg-primary",
  hiddenTemporal: true,
  payvalida: {
    merchant: "prestapolissas",
    entityCode: "",
    fixedHash:
      "57e6f37b17b586d276672a95532c4cf8df143f1b5401c45922388bd5490b688d5bb633f7a3eb958893bc334089273a49c25da630d9fbf342770bc00221429f8d",
  },
};

export default config;
