/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file PaymentForm/view.jsx
 * @description PaymentForm component
 */

/** Dependencies */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
/** Components */
import FormBuilder, { INPUT_TEXT, required, SUBMIT } from '../../../../components/Form';
/** Messages */
import messages from '../../messages';
/** Helpers */
import { setMask } from '../../../../utils/commons/helpers';

export class PaymentForm extends Component {
  /**
   * React Lifecycle method, render PaymentForm Container
   * @return { Component } PaymentForm
   */
  render() {
    this.setMask();

    return (
      <div className='col-lg-7 col-md-8 col-sm-10 col-12 mx-auto p-2'>
        <div className='d-flex flex-row justify-content-between mb-2 align-items-baseline'>
          <span className='fs-5 fw-bold align-bottom align-text-bottom'>
            <FormattedMessage {...messages.paymentValue} />
          </span>
        </div>
        <FormBuilder
          onSubmit={values => this.onClick(values)}
          fields={this.fields()}
        />
      </div>
    );
  }

  /**
   * fields its a helper function to render fields in forms
   */
  fields() {
    const {
      intl: { formatMessage },
      loading,
      payment
    } = this.props;

    return [
      {
        keyField: 'payment',
        type: INPUT_TEXT,
        className: 'text-end fw-bold fs-5',
        validators: [required],
        placeholder: '$',
        disabled: loading || payment !== 0,
        value: payment,
        pattern: '[0-9]*',
        inputmode: 'tel',
        currency: true,
        prefix: '$',
      },
      {
        keyField: 'submit',
        type: SUBMIT,
        label: formatMessage(messages.paymentButton),
        className: 'btn-secondary',
        disabled: loading
      },
    ];
  }

  /**
   * setMask
   */
  setMask() {
    setTimeout(() => setMask(), 100);
  }

  onClick = values => console.log('PaymentForm > onClick', Number(values.payment.split('.').join('')));
}

PaymentForm.propTypes = {
  payment: PropTypes.number,
  toPaymentInfo: PropTypes.func,
};

PaymentForm.defaultProps = {
  payment: 0,
  toPaymentInfo: () => false
};

export default injectIntl(PaymentForm);