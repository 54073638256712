/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file CreditBasic/view.jsx
 * @description CreditBasic component
 */

/** Dependencies */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { v4 } from 'node-uuid';
/** Messages */
import messages from '../../messages';
/** Helpers */
import { formatCurrency, dateFormatted } from '../../../../utils/commons/helpers';
/** Routes */
import { PATH_HOME } from '../../../../config/Routes';
/** Constants */
import { STATUS_FEE_PAID } from '../../constants';
import config from '../../../../environments';
const { hiddenTemporal } = config;

export class CreditBasic extends Component {
  /**
   * React Lifecycle method, render CreditBasic Container
   * @return { Component } CreditBasic
   */
  render() {
    return (
      <div className='mt-3 py-2 px-3 border rounded-2 bg-gray'>
        <div className='row'>
          {this.shopInfo()}
          {this.paymentSection()}
          {this.moreDetails()}
        </div>
      </div>
    );
  }

  /**
   * feesInfo
   */
  feesInfo() {
    const { fees, feesPaid } = this.props;

    if (!feesPaid || !fees) return null;

    return (
      <div>
        <span className='me-2'>
          <FormattedMessage {...messages.feesPaid} />
        </span>
        <span>
          {`${feesPaid} / ${fees}`}
        </span>
      </div>
    );
  }

  /**
   * shopInfo
   */
  shopInfo() {
    const {
      nameCommerce,
      product,
      dateLimit,
      statusCreditId,
    } = this.props;

    const showDateLimit = statusCreditId === STATUS_FEE_PAID
      ? null
      : (
        <>
          <span>
            <FormattedMessage {...messages.dayLimit} values={{ date: '' }} />
          </span>
          <span className='fw-bold'>
            {dateFormatted(dateLimit, 'DD MMMM YYYY')}
          </span>
        </>
      );

    return (
      <div className='col-lg-8 col-md-8 col-sm-8 col-12'>
        <div className='d-flex flex-row'>
          <i className='fas fa-store fa-3x me-3' />
          <div className='d-flex flex-column'>
            <span className='fw-bold'>{nameCommerce}</span>
            {product && <span className='fw-bold'>{product}</span>}
            {showDateLimit}
            {this.feesInfo()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * paymentButton
   */
  paymentButton() {
    const { feeValue, feeValuePaid } = this.props;
    const value = feeValue - feeValuePaid;

    if (hiddenTemporal) return null

    return (
      <button
        className='btn btn-primary'
        onClick={() => this.onClick(value)}
      >
        ${formatCurrency(value)}
      </button>
    );
  }

  /**
   * paymentSection
   */
  paymentSection() {
    const paymentButton = this.paymentButton();

    return (
      <>
        <div className='d-lg-block d-md-block d-sm-block d-none col-lg-4 col-md-4 col-sm-4 text-end'>
          {paymentButton}
        </div>

        <div className='d-lg-none d-md-none d-sm-none d-grid col-12 mt-2 text-end'>
          {paymentButton}
        </div>
      </>
    );
  }

  /**
   * moreDetails
   */
  moreDetails() {
    const { creditId, feeValuePaid, statusCreditId } = this.props;
    
    const encodedId = btoa(`${creditId}`);
    const encodedValuePaid = btoa(`${feeValuePaid}`);
    const [uuid] = v4().toLocaleUpperCase().split('-');
    const redirect = `${encodedId}-${encodedValuePaid}-${uuid}`;

    const message = statusCreditId === STATUS_FEE_PAID
      ? messages.moreDetails
      : messages.payNow;

    return (
      <div className='col-12 mt-2 text-end d-grid'>
        <Link
          type='button'
          className='btn btn-secondary'
          to={`${PATH_HOME}${redirect}`}
        >
          <FormattedMessage {...message} />
          <i className='fas fa-caret-right ms-2' />
        </Link>
      </div>
    );
  }

  onClick = () => console.log('click CreditBasic');
}

CreditBasic.propTypes = {
  creditId: PropTypes.number.isRequired,
  dateLimit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  feeValue: PropTypes.number.isRequired,
  fees: PropTypes.number.isRequired,
  feesPaid: PropTypes.number.isRequired,
};

export default CreditBasic;