/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Atuh/index.jsx
 * @description Atuh component
 */

/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
/** Components */
import Copyright from '../../Copyright';
import LogoPrestapolis from '../../LogoPrestapolis';
import Loader from '../../Loader';
import Error from '../../Error';

const AuthLayout = props => {
  const { children, loading, error, setError } = props;

  return (
    <>
      <div className='container'>
        <div className='row full-height'>
          <div className='align-self-start text-center col-12' />
          <div className='align-self-center col-lg-4 col-md-6 col-sm-12 mx-auto mt-2'>
            <LogoPrestapolis />
            {children}
          </div>
          <Copyright />
        </div>
      </div>
      <Loader show={loading} />
      <Error show={error} setError={setError} />
    </>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired
};

export default AuthLayout;