/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file TotalPayment/index.jsx
 * @description TotalPayment component
 */

/** Dependencies */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
/** Messages */
import messages from '../../messages';
/** Helpers */
import { formatCurrency, dateFormatted } from '../../../../utils/commons/helpers';

export class TotalPayment extends Component {
  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { month: dateLimit } = this.props;

    const month = dateFormatted(dateLimit, 'MMMM');

    return (
      <div className='py-2 px-3 border rounded-2 bg-gray d-flex flex-column flex-sm-row flex-md-row justify-content-between align-items-center'>
        <FormattedMessage {...messages.quotaToCancel} values={{ month }} />
        {this.paymentSection()}
      </div>
    );
  }

  /**
   * paymentButton
   */
  paymentButton() {
    const { totalPayment } = this.props;

    return (
      <button
        className='btn btn-primary'
        onClick={this.onClick}
      >
        ${formatCurrency(totalPayment)}
      </button>
    );
  }

  /**
   * paymentSection
   */
  paymentSection() {
    const paymentButton = this.paymentButton();

    return (
      <>
        <div className='d-lg-block d-md-block d-sm-block d-none col-lg-4 col-md-4 col-sm-4 text-end'>
          {paymentButton}
        </div>

        <div className='d-lg-none d-md-none d-sm-none d-grid col-12 mt-2 text-end'>
          {paymentButton}
        </div>
      </>
    );
  }

  onClick = () => console.log('click TotalPayment');
}

TotalPayment.propTypes = {
  month: PropTypes.string.isRequired,
  totalPayment: PropTypes.number.isRequired,
};

export default TotalPayment;