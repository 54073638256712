/** Dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
/** Containers */
import App from './containers/App';
import LanguageProvider from './containers/LanguageProvider';
/** Utils */
import reportWebVitals from './reportWebVitals';
import store from './store/baseStore';
import { translationMessages } from './utils/i18n';
/** Styles */
import 'bootstrap/dist/js/bootstrap';
import './index.scss';

/** Create redux store with history */
const MOUNT_NODE = document.getElementById('root');

const render = messages => ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <BrowserRouter>
          <App auth />
        </BrowserRouter>
      </LanguageProvider>
    </Provider>
  </React.StrictMode>,
  MOUNT_NODE
);

if (module.hot) {
  /**
   * Hot reloadable React components and translation json files
   * modules.hot.accept does not accept dynamic dependencies,
   * have to be constants at compile-time
   */
  module.hot.accept(['./utils/i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

/** Chunked polyfill for browsers without Intl support */
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/es.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

/** 
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
