/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Verify/actionTypes.js
 * @description action types for Verify component
 */

export const SET_LOGIN = 'SET_LOGIN';
