/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Fee/index.jsx
 * @description Fee component
 */

/** Dependencies */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
/** Helpers */
import { formatCurrency, dateFormatted } from '../../utils/commons/helpers';
/** Constants */
import { STATUS_FEE_PAID } from './constants';
/** Helpers */
import { todayDiff } from '../../utils/commons/helpers';
/** Messages */
import messages from './messages';
/** Constants */
import config from '../../environments';
const { hiddenTemporal } = config;

export class Fee extends Component {
  /**
   * React Lifecycle method, render Fee Container
   * @return { Component } Fee
   */
  render() {
    const { selectAll, index, statusFeeId } = this.props;

    const emphasis = index === 0 && statusFeeId !== STATUS_FEE_PAID
      ? ' border-primary'
      : '';

    return (
      <div className={`mx-auto mt-3 py-2 px-3 border rounded-2 bg-gray${emphasis} `}>
        <div className='row'>
          {this.renderSingleCheckbox()}
          {!selectAll && this.renderSingleInfo()}
          {selectAll && this.renderSelectAllLabel()}
        </div>
      </div>
    );
  }

  /**
   * renderSingleCheckbox
   */
  renderSingleCheckbox() {
    const { feeId, feeValue, onClick, selectAll, checked, statusFeeId } = this.props;
    const name = selectAll ? 'select-all' : `fee-${feeId}`;
    const value = selectAll ? 'select-all' : feeValue;

    if (statusFeeId === STATUS_FEE_PAID) return null;

    return (
      <div className='col-2 text-center my-auto'>
        <input
          className='form-check-input form-control-lg pp-check'
          type='checkbox'
          name={name}
          value={value}
          checked={checked}
          onChange={() => false}
          onClick={({ target }) => onClick(target.checked, feeId)}
        />
      </div>
    );
  }

  /**
   * boxInfo
   * @param { object } label 
   * @param { string | number } value 
   * @param { boolean } emphasis 
   */
  boxInfo(label, value, emphasis = false) {
    if (!value) return null;

    return (
      <div className='col-12 mb-1 d-flex justify-content-between'>
        <span className='me-1'>
          <FormattedMessage {...label} />
        </span>
        <span className={emphasis ? 'fw-bold' : ''}>
          {value}
        </span>
      </div>
    );
  }

  /**
   * renderWhenFirstPayment
   */
  renderWhenFirstPayment() {
    const { numberFee, statusFeeId, platformCost, paymentValue, feeValue } = this.props;

    const feeBalance = paymentValue - (feeValue + platformCost);

    if (numberFee !== 1) return null;

    if (statusFeeId === STATUS_FEE_PAID) return (
      <>
        {platformCost !== 0 && this.boxInfo(messages.platformCost, `$${formatCurrency(platformCost)}`)}
        {platformCost !== 0 && this.boxInfo(messages.feeBalance, `$${formatCurrency(feeBalance)}`)}
        {this.boxInfo(messages.feeValue, `$${formatCurrency(feeValue)}`)}
      </>
    );

    return (
      <>
        {this.boxInfo(messages.paymentValue, paymentValue ? `$${formatCurrency(paymentValue)}` : null)}
      </>
    );
  }

  /**
   * renderDateLimit
   */
  renderDateLimit() {
    const {
      intl: { formatMessage },
      dateLimit,
      statusFeeId,
    } = this.props;
    const dateDiff = this.feeInDelay();

    const formattedDateLimit = dateFormatted(dateLimit, 'DD MMM YYYY');

    if (statusFeeId === STATUS_FEE_PAID) {
      return this.boxInfo(messages.dateLimit, formattedDateLimit, true);
    }
    
    const value = dateDiff 
      ? formatMessage(messages.immediatePayment)
      : formattedDateLimit;
    
    return this.boxInfo(messages.dateLimit, value, true);
  }

  /**
   * renderLastRow
   */
  renderLastRow() {
    const {
      index,
      feeId,
      feeValue,
      statusFeeId,
      paymentValue,
      onClick,
    } = this.props;

    const dateDiff = this.feeInDelay();
    const currencyFormatted = `$${formatCurrency(feeValue)}`;

    const feeValueEmphasis = index === 0
      ? (
        <button
          className={`fs-5 fw-bold btn btn-${dateDiff ? 'danger' : 'primary'}`}
          onClick={() => onClick(true, feeId)}
        >
          {currencyFormatted}
        </button>
      )
      : <span className='fs-5'>{currencyFormatted}</span>;

    if (statusFeeId === STATUS_FEE_PAID) {
      return (
        <>
          <span className='me-1'>
            <FormattedMessage {...messages.feePaid} />
          </span>
          <span className='fs-5'>
            ${formatCurrency(paymentValue)}
          </span>
        </>
      );
    }

    return (
      <>
        <span className='me-1'>
          <FormattedMessage {...messages.paymentValue} />
        </span>
        {feeValueEmphasis}
      </>
    );
  }

  /**
   * renderSingleInfo
   */
  renderSingleInfo() {
    const {
      rawFeeValue,
      valuePaid,
      datePayment,
      numberFee,
      daysDelay,
      statusFeeId,
    } = this.props;

    return (
      <div className={`col-${statusFeeId === STATUS_FEE_PAID ? '12' : '10'}`}>
        <div className='row'>
          <div className='col-12'>
            <div className='row g-0'>
              {!hiddenTemporal && this.boxInfo(messages.numberFee, numberFee)}
              {this.boxInfo(messages.daysDelay, daysDelay)}
              {this.renderDateLimit()}
              {statusFeeId === STATUS_FEE_PAID ? this.boxInfo(messages.datePayment, datePayment ? dateFormatted(datePayment, 'DD MMM YYYY') : null) : null}
              {this.boxInfo(messages.feeValue, rawFeeValue ? `$${formatCurrency(rawFeeValue)}` : null)}
              {this.boxInfo(statusFeeId === STATUS_FEE_PAID ? messages.feeBalance : messages.feePaid, valuePaid ? `$${formatCurrency(valuePaid)}` : null)}
              {this.renderWhenFirstPayment()}
            </div>
          </div>
          <div className='col-12 mt-2 text-end fw-bold'>
            {this.renderLastRow()}
          </div>
        </div>
      </div>
    );
  }

  /**
   * renderSingleInfo
   */
  renderSelectAllLabel() {
    return (
      <div className='col-10 mt-1 d-flex align-items-center'>
        <FormattedMessage {...messages.selectAll} />
      </div>
    );
  }

  /**
   * feeInDelay
   */
   feeInDelay() {
    const { dateLimit } = this.props;
    const dateDiff = todayDiff(dateLimit);
    return dateDiff < 1;
  }
}

Fee.propTypes = {
  index: PropTypes.number,
  creditId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  feeId: PropTypes.number,
  feeValue: PropTypes.number,
  rawFeeValue: PropTypes.number,
  valuePaid: PropTypes.number,
  dateLimit: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  statusFeeId: PropTypes.number,
  onClick: PropTypes.func,
  selectAll: PropTypes.bool,
};

Fee.defaultTypes = {
  creditId: 0,
  feeId: 0,
  feeValue: 0,
  valuePaid: 0,
  dateLimit: Date.now(),
  statusFeeId: 0,
  onClick: () => true,
  selectAll: false
};

export default injectIntl(Fee);