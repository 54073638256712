/** Dependencies */
import { call, put } from "redux-saga/effects";
/** Request */
import request from "../utils/commons/fetch";
/** Actions */
import * as globalActions from "../store/actions";
/** Constants */
import config from "../environments";
const { baseUrl } = config;

export function* creditByDocument(action) {
  const { payload: document } = action;

  const url = `${baseUrl}/Prestapolis/v2/credits/document/${document}`;
  const options = { method: "GET" };

  try {
    yield put(globalActions.setLoading(true));
    const { content } = yield call(request, url, options);
    const [firstCredit] = content;
    const {
      cupo: {
        datosPersona: { firstName, firstLastName },
      },
    } = firstCredit;
    yield put(globalActions.setUsername(`${firstName} ${firstLastName}`));

    const credits = content.map((credit) => {
      const {
        id: creditId,
        valor_cuota: feeValue,
        valorabono: feeValuePaid,
        fechaproximopago: dateLimit,
        estadocredito: { id: statusCreditId },
        cupo: {
          id: quotaId,
          valorCupoAprobado: quotaApproved,
          valorCupoLibre: quotaAvailable,
        },
        gastosacumulados: {
          id: gastoID,
          pagoTotal: gastoTotal,
          pagoMinimo: gastoMinimo,
        },
        cuotas: fees,
        cuotaspagadas: feesPaid,
        detailsDto: { nameCommerce, product },
      } = credit;

      return {
        creditId,
        statusCreditId,
        quotaId,
        dateLimit,
        feeValue,
        feeValuePaid,
        quotaApproved,
        quotaAvailable,
        firstName,
        firstLastName,
        fees,
        feesPaid,
        nameCommerce,
        product,
        gastoID,
        gastoTotal,
        gastoMinimo,
      };
    });
    yield put(globalActions.setCredits(credits));
    yield put(globalActions.setLoading(false));
  } catch (error) {
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
  }
}

export function* planByCreditId(action) {
  const { payload: document } = action;

  const url = `${baseUrl}/Prestapolis/v2/payment/credit/${document}`;
  const options = { method: "GET" };

  try {
    yield put(globalActions.setLoading(true));
    const { content } = yield call(request, url, options);
    const paymentPlan = content.map((fee) => {
      const {
        id: feeId,
        fechalimite: dateLimit,
        fechapago: datePayment,
        valorCuotaTotal: feeValue,
        valorpagado: paymentValue,
        saldoincial: balance,
        tiposEstado: { id: statusFeeId },
        nocuota: numberFee,
        diasmora: daysDelay,
        costoplataforma: platformCost,
      } = fee;

      return {
        feeId,
        dateLimit,
        datePayment,
        feeValue,
        paymentValue,
        balance,
        statusFeeId,
        numberFee,
        daysDelay,
        platformCost,
      };
    });
    yield put(globalActions.setPaymentPlan(paymentPlan));
    yield put(globalActions.setLoading(false));
  } catch (error) {
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
  }
}

export function* getCreditsByDocument(action) {
  const { payload: document, callback } = action;
  const url = `${baseUrl}/Prestapolis/v2/credits/activos/${document}`;
  const options = { method: "GET" };

  try {
    yield put(globalActions.setLoading(true));
    const response = yield call(request, url, options);
    yield put(globalActions.setLoading(false));
    if (callback) callback(response);
  } catch (error) {
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
  }
}

export function* orderNotRecurrent(action) {
  const { payload, callback } = action;
  const url = `${baseUrl}/Prestapolis/v1/order/other/random`;
  const options = { method: "POST", data: payload };

  try {
    yield put(globalActions.setLoading(true));
    const response = yield call(request, url, options);
    yield put(globalActions.setLoading(false));
    if (callback) callback(response);
  } catch (error) {
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
  }
}
