/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Verify/index.js
 * @description Verify Container
 */

/** Dependencies */
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

/** View */
import Verify from './view';

/** Redux */
import * as globalActions from '../../store/actions';
import * as actions from './redux/actions';

/**
 * Get Actions to components
 * @param { function } dispatch - Run action to execute in redux
 */
export const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(globalActions, dispatch),
  actions: bindActionCreators(actions, dispatch),
});

/**
 * Get props to components
 * @param { function } state - Redux state
 */
const mapStateToProps = state => ({
  loading: state.loading,
  document: state.document,
  phone: state.phone,
});

/**
 * Prepare actions and data to Redux
 */
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Verify);