/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file helper.js
 * @description General helper to app
 */

/** Dependencies */
import moment from "moment-timezone";
import VMasker from "vanilla-masker";

/**
 * Capitalize first letter of each word
 * @param { string } str - Character to process
 * @return { string } - Word with first letter capitalize
 */
export const capitalize = (str) =>
  str && str.length > 0
    ? str.replace(
        /\w\S*/g,
        (txt) => `${txt.charAt(0).toUpperCase()}${txt.substr(1)}`
      )
    : "";

/**
 * Validate if String is JSON
 * @param { string } str - String to validate
 * @return { boolean } Result of validation
 */
export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

/**
 * Handler to parse values to integer
 * @param { string } value - a string
 */
export const parseToInt = (value) => {
  const parsedValue = parseInt(value, 10);
  return !parsedValue ? false : parsedValue;
};

/**
 * Handler to format values to currency
 * @param { string } value - a string
 */
export const formatCurrency = (value) =>
  value
    ? value
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")
        .replace(/\.00$/, "")
    : "0";

export const formatFiltered = (value) => value.replace(/\D/g, "");

/**
 * Handler to format boolean to string
 * @param { string } value - a string
 */
export const renderBool = (value) => (value ? "Yes" : "No");

/**
 * Generate a random UUID value.
 * @returns { String } A string unique identifier.
 */
export const generateUUID = () =>
  Math.random().toString(36).substring(2) + Date.now().toString(36);

/**
 * Generate formatted date
 * @param { number } date A date
 * @return { string } A date formatted
 */
export const dateFormatted = (date, format) => {
  return moment.tz(date, "America/Bogota").format(format);
};

export const todayDiff = (date) => {
  const today = moment();
  const parsedDate = moment(date);

  return parsedDate.diff(today, "days");
};

/**
 * Process range value to singular expresion
 * @param { string } value - Value to process
 * @param { string } expresion - Expresion to divide value
 * @param { string } separator - Custom value to divide  value
 * @param { string } emptyValue - Returner when value is invalid
 * @return { string } value process
 */
export const processRangeValue = (value, expresion, separator, emptyValue) =>
  typeof value !== "undefined" && value
    ? value.toString().split(expresion).join(separator)
    : emptyValue;

/**
 * Handler to get years from year provided to now
 * @param { number } initialYear - initial year to validate
 * @return { Array } - Array with years to show
 */
export const getYears = (initialYear) => {
  const currentYear = moment().year();
  return Array(currentYear - (currentYear - (currentYear - initialYear)))
    .fill("")
    .map((_year, index) => currentYear - index);
};

/**
 * Handler to compare to objects
 * @param { object } objectA - first object
 * @param { object } objectB - second object
 * @return { boolean } - Result of validation
 */
export const compareObj = (objectA, objectB) => {
  const aKeys = Object.keys(objectA).sort();
  const bKeys = Object.keys(objectB).sort();

  if (aKeys.length !== bKeys.length) return false;

  if (aKeys.join("") !== bKeys.join("")) return false;

  for (let i = 0; i < aKeys.length; i += 1) {
    if (objectA[aKeys[i]] !== objectB[bKeys[i]]) return false;
  }

  return true;
};

/**
 * setMask
 */
export const setMask = () => {
  const nodeElements = document.getElementsByClassName("data-js-input");
  const options = {
    precision: 2,
    separator: ".",
    delimiter: ".",
    zeroCents: false,
  };
  VMasker(nodeElements).maskMoney(options);
};

/**
 * b64toBlob
 * @param { string } b64Data
 * @param { string } contentType
 * @param { number } sliceSize
 */
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const setCookie = (key, value, expire = false) => {
  let cookie = `${key}=${value}; path=/`;
  if (expire) {
    const expiration = new Date(Date.now() + 300000);
    cookie += `; expires=${expiration.toUTCString()}`;
  }
  document.cookie = cookie;
};

export const getCookie = (key) => {
  const [cookie] = document.cookie
    .split(";")
    .filter((text) => String(text).includes(key))
    .join("")
    .split("=")
    .reverse();
  return cookie;
};
