/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file SelectMethod/actionTypes.js
 * @description action types for SelectMethod component
 */

export const GET_QUICK_PAYMENT = 'GET_QUICK_PAYMENT';
export const CREATE_ORDER = 'CREATE_ORDER';
