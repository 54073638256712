/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file redux/actionTypes.js
 * @description actions types for CreditDetails component
 */

export const GET_PAYMENT_PLAN = 'GET_PAYMENT_PLAN';
