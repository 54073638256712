/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Button/index.jsx
 * @description Button component
 */

/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
/** Styles */
import './styles.scss';

const Button = props => {
  const { className, onClick, label, disabled } = props;

  const localClassName = 'btn'
    .concat(!!className && className.length > 0 ? ` ${className}` : '');

  return (
    <div className='d-grid gap-2 mb-3'>
      <button
        className={localClassName}
        onClick={onClick}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;