/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Home/messages.js
 * @description Home component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Home';

export default defineMessages({
  myQuota: { id: `${parent}.myQuota` },
  quotaToCancel: { id: `${parent}.quotaToCancel` },
  dayLimit: { id: `${parent}.dayLimit` },
  moreDetails: { id: `${parent}.moreDetails` },
  payNow: { id: `${parent}.payNow` },
  feesPaid: { id: `${parent}.feesPaid` },
  creditsActive: { id: `${parent}.creditsActive` },
  creditsPaid: { id: `${parent}.creditsPaid` },
});