/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Resend/messages.js
 * @description Resend component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';
/** Other messages */
import loginMessages from '../Login/messages';

const parent = 'Resend';

export default defineMessages({
  loginButton: loginMessages.loginButton,
  resendCodeTitle: { id: `${parent}.resendCodeTitle` },
  makeCallTitle: { id: `${parent}.makeCallTitle` },
  resendCodeDescription: { id: `${parent}.resendCodeDescription` },
  makeCallDescription: { id: `${parent}.makeCallDescription` },
});