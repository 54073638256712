/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Verify/saga.js
 */

/** Dependencies */
import { takeLatest } from 'redux-saga/effects';
/** Services */
import * as authService from '../../../services/auth';
/** Constants */
import { RESEND } from './actionTypes';

export default function* loginSaga() {
  yield takeLatest(RESEND, authService.validate);
}