/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file FindNext/messages.js
 * @description FindNext component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'FindNext';

export default defineMessages({
  documentPayer: { id: `${parent}.documentPayer` },
  amountPay: { id: `${parent}.amountPay` },
  selectMethodButton: { id: 'Buttons.selectMethod' },
});
