/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Anonymous/index.jsx
 * @description Anonymous component
 */

/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
/** Components */
import Loader from '../../Loader';
import Error from '../../Error';

const Anonymous = props => {
  const { children, loading, error, setError } = props;

  return (
    <>
      <div className='container '>{/* container-mt-4 */}
        {children}
      </div>
      <Loader show={loading} />
      <Error show={error} setError={setError} />
    </>
  );
};

Anonymous.propTypes = {
  children: PropTypes.any.isRequired
};

export default Anonymous;