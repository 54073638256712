/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Input/index.jsx
 * @description Input component
 */

/** Dependencies */
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
/** Form config */
import { composeValidators } from '../../config';
/** Styles */
import './styles.scss';

export class Input extends Component {
  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { keyField, validators, currency } = this.props;
    const localValidators = !!validators || validators.length > 0
      ? composeValidators(...validators)
      : false;

    if (currency) {
      return (
        <Field
          name={keyField}
          validate={localValidators}
        >
          {fieldProps => this.renderNumberContent(fieldProps)}
        </Field>
      );
    }

    return (
      <Field
        name={keyField}
        validate={localValidators}
      >
        {fieldProps => this.renderContent(fieldProps)}
      </Field>
    );
  }

  /**
   * renderError is a helper function to render Error
   * @param { object } meta - 
   */
  renderError(meta) {
    const { error, touched } = meta;

    if (!!error && touched) {
      return (
        <div className='error-field'>
          <FormattedMessage {...error} />
        </div>
      );
    }

    return null;
  }

  /**
   * renderContent is a helper function to render content inside field
   * @param { object } fieldProps - props of field
   */
  renderContent = fieldProps => {
    const {
      label,
      type,
      placeholder,
      hidden,
      disabled,
      className,
      pattern,
      inputmode: inputMode,
      maxlength: maxLength,
      currency,
      onChange,
      value
    } = this.props;
    const { input, meta } = fieldProps;
    const inputProps = { type, placeholder, disabled, pattern, inputMode, maxLength };
    const onChangeProps = onChange && typeof value === 'string' ? { onChange, value } : {};
    const inputClassName = className ? ` ${className}` : '';

    return (
      <div className={`mb-3${hidden ? ' d-none' : ''}`}>
        <input
          className={`form-control${inputClassName}`}
          {...input}
          {...inputProps}
          {...onChangeProps}
          disabled={Boolean(currency)}
        />
        {label && <label>{label}</label>}
        {this.renderError(meta)}
      </div>
    );
  }

  /**
   * renderNumberContent is a helper function to render content inside field
   * @param { object } fieldProps - props of field
   */
  renderNumberContent = fieldProps => {
    const {
      label,
      type,
      placeholder,
      hidden,
      disabled,
      className,
      pattern,
      inputmode: inputMode,
      prefix,
      onChange
    } = this.props;
    const { input, meta } = fieldProps;
    const inputProps = { type, placeholder, disabled, pattern, inputMode, prefix };
    const onChangeProps = onChange ? { onValueChange: onChange } : {};
    const inputClassName = className ? ` ${className}` : '';

    return (
      <div className={`mb-3${hidden ? ' d-none' : ''}`}>
        <NumberFormat
          className={`form-control${inputClassName}`}
          {...input}
          {...inputProps}
          {...onChangeProps}
          thousandSeparator='.'
          decimalSeparator=','
        />
        {label && <label>{label}</label>}
        {this.renderError(meta)}
      </div>
    );
  }
}

Input.propTypes = {
  keyField: PropTypes.string.isRequired,
  validators: PropTypes.arrayOf(PropTypes.func).isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  currency: PropTypes.bool,
  pseudoCurrency: PropTypes.bool,
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
  ]),
  values: PropTypes.any,
};

Input.defaultProps = {
  label: false,
  currency: false,
  pseudoCurrency: false,
  hidden: false,
  disabled: false,
  onChange: false,
  values: false,
};

export default Input;