/** Dependencies */
import axios from "axios";
/** Commons */
import { getToken } from "./localstorage";
import { getCookie } from "./helpers";
/** Routes */
import { PATH_UNAUTHORIZED } from "../../config/Routes";
/** Constants */
const HTTP_STATUS_UNAUTHORIZED = 401;

axios.interceptors.request.use(
  (request) => {
    const { url } = request;
    if (url.includes("validate") || url.includes("tokenbydocument"))
      return request;

    let token;
    const storageToken = getToken();
    if (storageToken) token = storageToken;

    const cookieToken = getCookie("token");
    if (cookieToken) token = cookieToken;

    request.headers["Authorization"] = `Bearer ${token}`;
    return request;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response) {
      const { response } = error;
      const { status } = response;
      const { href } = window.location;
      if (status === HTTP_STATUS_UNAUTHORIZED && !href.includes("login")) {
        window.location.href = PATH_UNAUTHORIZED;
      }
    }
    return Promise.reject(error);
  }
);

export const request = (url, options = {}) =>
  axios.request({ url, ...options });

export default request;
