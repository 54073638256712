/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Error/messages.js
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Error';

export default defineMessages({
  error: { id: `${parent}.error` },
  ok: { id: `${parent}.ok` },
});