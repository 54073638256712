/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file NotFound/index.js
 * @description NotFound container
 */

/** Dependencies */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
/** Constants */
import { PATH_HOME } from '../../config/Routes';
/** Messages */
import messages from './messages';

const NotFound = () => {
  const history = useHistory();

  const handleNavigation = () => history.push(PATH_HOME);

  const { backButton, notFound } = messages;

  return (
    <div className="d-flex justify-content-center align-items-center full-height">
      <FormattedMessage {...notFound} />
      <button
        className="btn btn-primary"
        onClick={handleNavigation}
      >
        <FormattedMessage {...backButton} />
      </button>
    </div>
  )
}

export default NotFound;