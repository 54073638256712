/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file redux/saga.js
 */

/** Dependencies */
import { takeLatest } from 'redux-saga/effects';
/** Services */
import * as creditsService from '../../../services/credits';
/** Constants */
import { GET_PAYMENT_PLAN } from './actionTypes';

export default function* homeSaga() {
  yield takeLatest(GET_PAYMENT_PLAN, creditsService.planByCreditId);
}