/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Login/actions.js
 * @description actions for Login component
 */

import { GET_VALIDATION, GET_TOKEN } from "./actionTypes";

export const getValidation = (credentials, callback) => ({
  type: GET_VALIDATION,
  payload: credentials,
  callback,
});

export const getToken = (payload, callback, rejectCallback) => ({
  type: GET_TOKEN,
  payload,
  callback,
  rejectCallback,
});
