/** Dependencies */
import { call, put } from "redux-saga/effects";
/** Request */
import request from "../utils/commons/fetch";
/** Actions */
import * as globalActions from "../store/actions";
/** Utils */
import { setCookie } from "../utils/commons/helpers";
/** Constants */
import config from "../environments";
const { baseUrl } = config;

export function* validate(action) {
  const { payload, callback } = action;
  const { document, phone } = payload;

  const url = `${baseUrl}/Prestapolis/v1/api/client/validate`;
  const options = { method: "POST", data: payload };

  try {
    yield put(globalActions.setLoading(true));
    const response = yield call(request, url, options);
    yield put(globalActions.setDocument(document));
    yield put(globalActions.setPhone(phone));
    yield put(globalActions.setLoading(false));

    if (callback) callback(response);

  } catch (error) {
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
  }
}

export function* login(action) {
  const { payload, callback } = action;

  const url = `${baseUrl}/api/auth/client/login`;
  const options = { method: "POST", data: payload };

  try {
    yield put(globalActions.setLoading(true));
    const { accessToken } = yield call(request, url, options);
    yield put(globalActions.setAccessToken(accessToken));
    yield put(globalActions.setLoading(false));

    if (callback) callback();
  } catch (error) {
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
  }
}

export function* getToken(action) {
  const { rejectCallback, callback, payload } = action;

  const url = `${baseUrl}/Prestapolis/v1/api/client/tokenbydocument`;
  const data = { document: String(payload) };
  const options = { method: "POST", data };

  try {
    yield put(globalActions.setLoading(true));
    const { respuesta: token } = yield call(request, url, options);
    setCookie("token", token, true);
    yield put(globalActions.setLoading(false));
    
    if (callback) callback();
  } catch (error) {
    console.error(error);
    yield put(globalActions.setError("E00"));
    yield put(globalActions.setLoading(false));
    if (rejectCallback) rejectCallback();
  }
}
