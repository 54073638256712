import {
  SET_LOADING,
  SET_ERROR,
  SET_LOCALE,
  SET_DOCUMENT,
  SET_PHONE,
  SET_ACCESS_TOKEN,
  SET_USERNAME,
  SET_CREDITS,
  UNSET_SESSION,
  SET_PAYMENT_PLAN,
} from './actionTypes';
import { LOCALE_DEFAULT } from '../utils/i18n';

export const defaultState = {
  loading: false,
  error: undefined,
  locale: LOCALE_DEFAULT,
  document: undefined,
  phone: undefined,
  accessToken: undefined,
  username: undefined,
  credits: undefined,
  paymentPlan: undefined,
};

export const reducer = (state = defaultState, action = {}) => {
  /* debugger */

  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return { ...state, loading: payload };
    case SET_ERROR:
      return { ...state, error: payload };
    case SET_LOCALE:
      return { ...state, locale: payload };
    case SET_DOCUMENT:
      return { ...state, document: payload };
    case SET_PHONE:
      return { ...state, phone: payload };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken
      };
    case SET_USERNAME:
      return {
        ...state,
        username: payload.username
      };
    case SET_CREDITS:
      return { ...state, credits: payload };
    case SET_PAYMENT_PLAN:
      return { ...state, paymentPlan: payload };
    case UNSET_SESSION:
      return { ...state, ...defaultState };
    default:
      return state;
  }
}