/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Auth/index.js
 * @description Auth HOC
 */

/** Dependencies */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
/** Redux */
import * as globalActions from '../../store/actions';
/** Constants */
import { PATH_AUTH } from '../../config/Routes';

/**
 * Get Actions to components
 * @param { function } dispatch - Run action to execute in redux
 */
export const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(globalActions, dispatch),
});

/**
 * Get props to components
 * @param { function } state - Redux state
 */
export const mapStateToProps = state => ({
  loading: state.loading,
  document: state.document
});

const Auth = WithRedux => WrappedComponent => {
  class WithAuth extends Component {
    /**
     * Initialize WithAuth hoc
     * @param { object } props - Component properties
     */
    constructor(props) {
      super(props);
      this.goToAuth(props);
    }

    /**
     * React Lifecycle method, render route if couple level permission necessary
     * @return { Component } WrappedComponent page container to render
     */
    render() {
      const { document } = this.props;

      return document
        ? <WrappedComponent {...this.props} />
        : null;
    }

    goToAuth(props) {
      const { history, document } = props;
      if (!document) history.push(PATH_AUTH);
    }
  }

  if (WithRedux) {
    // Prepare actions and data to Redux
    const withConnect = connect(mapStateToProps, mapDispatchToProps);

    return compose(withConnect)(WithAuth);
  }

  return WithAuth;
}

export default Auth;