/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file SelectMethod/saga.js
 */

/** Dependencies */
import { takeLatest } from 'redux-saga/effects';
/** Services */
import * as creditsService from '../../../services/credits';
/** Constants */
import { GET_QUICK_PAYMENT, CREATE_ORDER } from './actionTypes';

export default function* selectMethodSaga() {
  yield takeLatest(GET_QUICK_PAYMENT, creditsService.getCreditsByDocument);
  yield takeLatest(CREATE_ORDER, creditsService.orderNotRecurrent);
}