/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file redux/actionTypes.js
 * @description actions types for Home component
 */

export const GET_CREDITS = 'GET_CREDITS';
