/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author danielCastano
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
/** Routes */
import { PATH_AUTH_LOGIN } from "../../config/Routes";
/** Redux */
import * as globalActions from "../../store/actions";
/** Messages */
import messages from "./messages";
/** Constants */

/**
 * Get Actions to components
 * @param { function } dispatch - Run action to execute in redux
 */
export const mapDispatchToProps = (dispatch) => ({
  globalActions: bindActionCreators(globalActions, dispatch),
});

/**
 * Get props to components
 * @param { function } state - Redux state
 */
const mapStateToProps = (state) => ({
  loading: state.loading,
});

/**
 * Prepare actions and data to Redux
 */
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const NotRegistered = (props) => {
  const {
    globalActions: { unsetSession },
  } = props;

  const { loginButton } = messages;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center full-height">
      <h2>Usuario no registrado</h2>
      <div className="text-center mx-2">
        <span className="me-1">
          Aún no eres parte de Prestápolis, te invitamos a{" "}
          <a href="https://prestapolis.com">https://prestapolis.com </a>
          busca en nuestra red de comercios aliados y accede a nuestros
          servicios financieros.
        </span>
        <br className="d-inline" />
        <br className="d-inline" />
        <span className="me-1">Vuelve a loguearte en</span>
        <br className="d-inline" />
        <Link to={PATH_AUTH_LOGIN} onClick={unsetSession}>
          <FormattedMessage {...loginButton} />
        </Link>
      </div>
    </div>
  );
};

export default compose(withConnect)(NotRegistered);
