export const AGREEMENT_BANCOLOMBIA = 'BANCOLOMBIA';
export const AGREEMENT_BALOTO = 'BALOTO';
export const AGREEMENT_PUNTO_RED = 'PUNTO RED';
export const AGREEMENT_WESTER_UNION = 'WESTER UNION';
export const AGREEMENT_EFECTY = 'EFECTY';

export const TABS = [
  {
    label: AGREEMENT_BANCOLOMBIA,
    image: '/assets/images/bancolombia3.png',
    code: 54245,
    numberRef: true,
  },
  /*{
    label: AGREEMENT_BALOTO,
    image: '/assets/images/baloto.png',
    code: 950715,
  },*/
  {
    label: AGREEMENT_PUNTO_RED,
    image: '/assets/images/puntored1.png',
    code: 110342,
    numberRef: false,
  },
  // {
  //   label: AGREEMENT_WESTER_UNION,
  //   image: '/assets/images/westeru1.png',
  //   code: 110342,
  //   numberRef: false,
  // },
  {
    label: AGREEMENT_EFECTY,
    image: '/assets/images/efecty2.png',
    code: 113027 + ' o Prestapolis',
    numberRef: false,
  }
];

export const DAYS_ADDITIONAL = 6;

export const IS_ENABLED = false;