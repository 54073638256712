/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file UserInfo/index.jsx
 * @description UserInfo component
 */

/** Dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
/** Componentes */
import Copyright from '../Copyright';
/** Messages */
import messages from './messages';
/** Helpers */
import { formatCurrency } from '../../utils/commons/helpers';
/** Styles */
import './styles.scss';

const UserInfo = props => {
  const { username, quotaAvailable, quotaApproved } = props;

  const percent = (quotaAvailable * 100) / quotaApproved;

  const renderProgressBar = (
    <div className='col-12 mt-2'>
      <div className='progress border pp-progressbar'>
        <div
          className='progress-bar progress-bar-striped bg-info'
          role='progressbar'
          style={{ width: `${percent}%` }}
          aria-valuenow={percent}
          aria-valuemin='0'
          aria-valuemax='100'
        >
          {percent.toFixed(1)}%
        </div>
      </div>
    </div>
  );

  const renderPersonalInfo = (
    <>
      <div className='col-12 fs-2 d-flex justify-content-between'>
        <div>
          <span>
            <FormattedMessage {...messages.hello} />
          </span>
          <span className='fw-bold'>
            {username}
          </span>
        </div>
      </div>
      <div className='border-bottom' />
    </>
  );

  const renderQuotaAvailable = (
    <>
      <div className='col-12 mt-2'>
        <FormattedMessage {...messages.quotaAvailable} />
      </div>
      <div className='col-12 fs-5 ps-4'>
        <span className='fw-bold'>
          ${formatCurrency(quotaAvailable)}
        </span>
        <span className='mx-2'>
          <FormattedMessage {...messages.separator} />
        </span>
        <span className='fw-bold'>
          ${formatCurrency(quotaApproved)}
        </span>
      </div>
    </>
  );

  const renderQuotaApproved = (
    <>
      <div className='col-12 mt-2'>
        <FormattedMessage {...messages.quotaApproved} />
      </div>
      <div className='col-12 fs-5 ps-4 fw-bold'>
        ${formatCurrency(quotaApproved)}
      </div>
    </>
  );

  return (
    <>
      <div className='py-2 px-3 border rounded-2 bg-gray'>
        <div className='row'>
          {renderPersonalInfo}
          {renderQuotaAvailable}
          {renderProgressBar}
          {false && <div className='border-bottom mt-2' />}
          {false && renderQuotaApproved}
        </div>
      </div>
      <div className='mt-2 d-none d-lg-inline'>
        <Copyright />
      </div>
    </>
  );
}

UserInfo.propTypes = {
  quotaAvailable: PropTypes.number.isRequired,
  quotaApproved: PropTypes.number.isRequired,
  username: PropTypes.string,
  document: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

UserInfo.defaultProps = {
  username: '',
  document: '',
};

export default UserInfo;