/** Dependencies */
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
/** Containers */
import TotalPayment from './elements/TotalPayment';
import CreditBasic from './elements/CreditBasic';
/** Components */
import DefaultLayout from '../../components/Layout/Default';
import UserInfo from '../../components/UserInfo';
/** Helpers */
import { formatCurrency } from '../../utils/commons/helpers';
/** Messages */
import messages from './messages';
/** Constants */
import { STATUS_FILTER_ACTIVE_OR_DELAY, STATUS_FILTER_PAID, STATUS_FEE_PAID } from './constants';
import config from '../../environments';
const { hiddenTemporal } = config;

class Home extends Component {
  /**
   * Initialize Home
   * @param { object } props - Component properties
   */
  constructor(props) {
    super(props);

    this.state = {
      filter: STATUS_FILTER_ACTIVE_OR_DELAY
    };
  }

  /**
   * React Lifecycle method, call when mount component
   */
  componentDidMount() {
    this.getCredit();
  }

  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { history, globalActions, loading, error, document } = this.props;
    const { setLocale, setError } = globalActions;

    return (
      <DefaultLayout
        history={history}
        loading={loading}
        error={error}
        username={formatCurrency(Number(document.split('.').join('')))}
        setLocale={setLocale}
        setError={setError}
      >
        <div className='row mt-2'>
          {this.renderUserInfo()}
          {this.renderDivider()}
          {this.renderQuota()}
        </div>
      </DefaultLayout>
    );
  }

  /**
   * renderUserInfo its a helper function to user' info
   */
  renderUserInfo() {
    const { document, username, credits } = this.props;

    if (!document || !credits) return null;

    const [firstName] =  username.split(' ');

    const mapper = ({ quotaApproved, quotaAvailable }) => ({
      quotaAvailable,
      quotaApproved,
    });

    const filter = ({ statusCreditId }) => statusCreditId !== STATUS_FEE_PAID;

    const reducer = (acc, quota) => {
      acc.quotaApproved += quota.quotaApproved;
      acc.quotaAvailable += quota.quotaAvailable;
      return acc;
    };

    const quotas = credits
      ? credits.filter(filter).map(mapper)
      : [];

    const quotaReduced = quotas.length > 0
      ? quotas.reduce(reducer)
      : { quotaApproved: 0, quotaAvailable: 0 };

    return (
      <div className='col-sm-12 col-md-12 col-lg-4 mb-2'>
        <UserInfo
          username={firstName}
          quotaAvailable={quotaReduced.quotaAvailable}
          quotaApproved={quotaReduced.quotaApproved}
        />
      </div>
    );
  }

  /**
   * renderTotalPayment
   */
  renderTotalPayment() {
    const { credits } = this.props;

    if (credits.length < 2) return null;

    const mapper = ({ creditId, dateLimit, feeValue, feeValuePaid }) => ({
      creditId,
      dateLimit,
      feeValue,
      feeValuePaid,
    });

    const reducer = (acc, quota) => {
      const { feeValue, feeValuePaid } = quota;
      acc.feeValuePaid += feeValuePaid;
      acc.feeValue += feeValue;
      return acc;
    };

    const quotas = credits.length > 0
      ? credits.map(mapper)
      : [];    

    const month = quotas.length > 0
      ? quotas[0].dateLimit
      : new Date(Date.now());

    const paymentObj = quotas.length > 0
      ? quotas.reduce(reducer)
      : { feeValuePaid: 0, feeValue: 0 };

    const totalPayment = paymentObj.feeValue - paymentObj.feeValuePaid;

    return (
      <TotalPayment
        month={month}
        totalPayment={totalPayment}
      />
    );
  }

  /**
   * renderCreditBasic
   * @param { object } credit 
   * @param { number } index 
   */
  renderCreditBasic(credit, index) {
    const { creditId } = credit;

    return (
      <CreditBasic
        key={`${creditId}-${index}`}
        {...credit}
      />
    );
  }

  /**
   * renderCreditList
   */
  renderCreditList() {
    const { credits } = this.props;
    const { filter } = this.state;

    if (!credits || credits.length === 0) return null;

    if (credits.length === 1) {
      return (
        <div className='row'>
          <div className='col-12'>
            {credits.map(this.renderCreditBasic)}
          </div>
        </div>
      );
    }

    const setState = filter => this.setState({ filter });

    const creditsPaid = credits.filter(({ statusCreditId }) => statusCreditId === STATUS_FEE_PAID);
    const creditsActive = credits.filter(({ statusCreditId }) => statusCreditId !== STATUS_FEE_PAID);

    const showCredits = filter === STATUS_FILTER_PAID
      ? creditsPaid
      : creditsActive;

    return (
      <div className='row'>
        <div className='col-12'>
          <div className='btn-group btn-group-sm'>
            <button
              className={`btn btn-${filter !== STATUS_FILTER_PAID ? 'primary' : 'light'}`}
              onClick={() => setState(STATUS_FILTER_ACTIVE_OR_DELAY)}
            >
              <FormattedMessage {...messages.creditsActive} />
            </button>
            <button
              className={`btn btn-${filter === STATUS_FILTER_PAID ? 'primary' : 'light'}`}
              onClick={() => setState(STATUS_FILTER_PAID)}
            >
              <FormattedMessage {...messages.creditsPaid} />
            </button>
          </div>
        </div>

        <div className='col-12'>
          {showCredits.map(this.renderCreditBasic)}
        </div>
      </div>
    );
  }

  /**
   * renderQuota its a helper function to render quotas to pay
   */
  renderQuota() {
    return (
      <div className='col-sm-12 col-md-12 col-lg-8'>
        <span className='fs-2 fw-bold'>
          <FormattedMessage {...messages.myQuota} />
        </span>

        {!hiddenTemporal && this.renderTotalPayment()}

        {this.renderCreditList()}
      </div>
    );
  }

  /**
   * renderDivider its a helper function to separate components
   */
  renderDivider() {
    return <div className='mb-2 border-bottom d-lg-none d-md-inline d-sm-inline' />;
  }

  getCredit() {
    const {
      document,
      actions: { getCredits }
    } = this.props;
    getCredits(document);
  }
}

export default Home;