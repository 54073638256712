/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file MethodPSE/view.js
 * @description MethodPSE Container
 */

/** Dependencies */
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
/** Components */
import AnonymousLayout from '../../components/Layout/Anonymous';
/** Helpers */
import { formatCurrency } from '../../utils/commons/helpers';
/** Messages */
import messages from './messages';
/** Styles */
import HeadersPayment from '../../components/HeadersPayment';
import FormBuilder, {
  SELECT,
  SUBMIT,
} from '../../components/Form';

export class MethodPSE extends Component {
  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { globalActions, loading } = this.props;
    const { setError } = globalActions;
    const headerValues = [
      this.renderTotalPayment(),
    ];

    return (
      <AnonymousLayout
        loading={loading}
        setError={setError}
      >
        <div className='row py-3'>
          <HeadersPayment values={headerValues} />
          <div className='col-12 text-center mx-auto mt-2'>
            <strong>
              PSE
            </strong>
          </div>
          <div className='col-12 col-md-8 mx-auto mt-2'>
            {this.renderForm()}
          </div>
        </div>
      </AnonymousLayout>
    );
  }

  /**
   * renderForm
   */
  renderForm() {
    return (
      <div className='border pt-3 px-2'>
        <FormBuilder
          onSubmit={values => console.log(values)}
          fields={this.fields()}
        />
      </div>
    );
  }

  /**
   * fields its a helper function to render fields in forms
   */
  fields() {
    const {
      intl: { formatMessage },
      loading,
    } = this.props;

    console.log();

    return [
      {
        keyField: 'typePerson',
        type: SELECT,
        placeholder: formatMessage(messages.typePerson),
        options: [
          { label: 'hola', value: 0 },
          { label: 'hola', value: 1 },
        ]
      },
      {
        keyField: 'bank',
        type: SELECT,
        placeholder: formatMessage(messages.bankLabel),
        options: [
          { label: 'hola', value: 'world' },
        ]
      },
      {
        keyField: 'submit',
        type: SUBMIT,
        label: formatMessage(messages.buttonPayment),
        className: 'btn-primary',
        disabled: loading
      },
    ];
  }

  /**
   * renderTotalPayment
   */
  renderTotalPayment() {
    const value = this.getTotalPayment();

    return { label: messages.totalPayment, value };
  }

  /**
   * getTotalPayment
   */
  getTotalPayment() {
    const { payment: rawEncodedPayment } = this.getQueryParams();
    const [encodedPayment] = rawEncodedPayment.split('-');
    const payment = atob(encodedPayment);
    const value = formatCurrency(Number(payment));

    return `$${value}`;
  }

  /**
   * getEncodedValues
   */
  getEncodedValues() {
    const {
      match: {
        params: { document: encodedUuid },
      }
    } = this.props;
    const [encodedDocument] = encodedUuid.split('-');
    const document = atob(encodedDocument);
    return { document };
  }

  /**
   * getQueryParams
   */
  getQueryParams() {
    const {
      location: { search }
    } = this.props;
    const queryParams = {};
    search
      .replace(/\?/, '')
      .split('&')
      .forEach(queryParam => {
        const [key, value] = queryParam.split('=');
        queryParams[key] = value;
      });;
    return queryParams;
  }
}

export default injectIntl(MethodPSE);
