/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file UserInfo/messages.js
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'UserInfo';

export default defineMessages({
  documentType: { id: `${parent}.documentType` },
  hello: { id: `${parent}.hello` },
  quotaAvailable: { id: `${parent}.quotaAvailable` },
  quotaApproved: { id: `${parent}.quotaApproved` },
  separator: { id: `${parent}.separator` },
});