export const LOCALE_ES = 'es';
export const LOCALE_EN = 'en';

export const LOCALE_DEFAULT = LOCALE_ES;

export const LOCALES = [LOCALE_ES, LOCALE_EN];

const esTranslationMessages = require('./translations/es.json');
const enTranslationMessages = require('./translations/en.json');

/** Translations */
export const translationMessages = {
  es: esTranslationMessages,
  en: enTranslationMessages,
};