/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file CreditDetails/messages.js
 * @description CreditDetails component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'CreditDetails';

export default defineMessages({
  backButton: { id: 'Buttons.Back' },
  paymentPlan: { id: `${parent}.paymentPlan` },
  paymentValue: { id: `${parent}.paymentValue` },
  paymentButton: { id: `${parent}.paymentButton` },
  paymentInfo: { id: `${parent}.paymentInfo` },
  paymentForm: { id: `${parent}.paymentForm` },
  creditLabel: { id: `${parent}.creditLabel` },
  feesActivesOrDelay: { id: `${parent}.feesActivesOrDelay` },
  feesPaid: { id: `${parent}.feesPaid` },
});