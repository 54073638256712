/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file config/Routes.js
 * @description Routes
 */

/** Dependencies */
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
/** Containers */
import Home from "../containers/Home";
import CreditDetail from "../containers/CreditDetail";
import NotFound from "../containers/NotFound";
import Unauthorized from "../containers/Unauthorized";
import Login from "../containers/Login";
import Verify from "../containers/Verify";
import Resend from "../containers/Resend";
import PaymentsHome from "../containers/PaymentsHome";
import FindNext from "../containers/FindNext";
import SelectMethod from "../containers/SelectMethod";
import MethodCash from "../containers/MethodCash";
import MethodPSE from "../containers/MethodPSE";
import NotRegistered from "../containers/NotRegistered";
/** HOCs */
import WithAuth from "../hoc/Auth";

export const PATH_AUTH = "/auth";
export const PATH_AUTH_LOGIN = `${PATH_AUTH}/login`;
export const PATH_AUTH_CONFIRM = `${PATH_AUTH}/verify`;
export const PATH_AUTH_RESEND = `${PATH_AUTH}/resend`;
export const PATH_HOME = "/";
export const PATH_DETAIL = "/credit";
export const PATH_NOT_REGISTERED = "/not_registered";
export const PATH_UNAUTHORIZED = "/unauthorized";
export const PATH_PAYMENTS = "/payments";
export const PATH_PAYMENTS_FIND_NEXT = `${PATH_PAYMENTS}/find_next`;
export const PATH_PAYMENTS_SELECT_METHOD = `${PATH_PAYMENTS}/method`;

const Routes = (props) => {
  const { auth } = props;

  /** Initialize HOCs */
  const withAuth = WithAuth(auth);

  const routes = [
    { exact: true, path: PATH_UNAUTHORIZED, component: Unauthorized },
    { exact: true, path: PATH_NOT_REGISTERED, component: NotRegistered },
    // Redirects
    {
      exact: true,
      path: PATH_AUTH,
      children: <Redirect to={PATH_AUTH_LOGIN} />,
    },
    // Auth
    { exact: true, path: PATH_AUTH_LOGIN, component: Login },
    { exact: true, path: PATH_AUTH_CONFIRM, component: Verify },
    { exact: true, path: PATH_AUTH_RESEND, component: Resend },
    // Payments
    { exact: true, path: PATH_PAYMENTS, component: PaymentsHome },
    { exact: true, path: PATH_PAYMENTS_FIND_NEXT, component: FindNext },
    {
      exact: true,
      path: `${PATH_PAYMENTS_SELECT_METHOD}/:document`,
      component: SelectMethod,
    },
    {
      exact: true,
      path: `${PATH_PAYMENTS_SELECT_METHOD}/cash/:document`,
      component: MethodCash,
    },
    {
      exact: true,
      path: `${PATH_PAYMENTS_SELECT_METHOD}/pse/:document`,
      component: MethodPSE,
    },
    // Home
    { exact: true, path: PATH_HOME, component: withAuth(Home) },
    { exact: true, path: `${PATH_HOME}:id`, component: withAuth(CreditDetail) },
    // Not Found
    { exact: true, path: "", component: NotFound },
  ];

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
