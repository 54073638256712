/** Dependencies */
import { createStore, applyMiddleware } from 'redux';
import { reducer } from './reducer';
import createSagaMiddleware from 'redux-saga';
/** Sagas */
import loginSaga from '../containers/Login/redux/saga';
import verifySaga from '../containers/Verify/redux/saga';
import reSendSaga from '../containers/Resend/redux/saga';
import homeSaga from '../containers/Home/redux/saga';
import creditDetailSaga from '../containers/CreditDetail/redux/saga';
import selectMethodSaga from '../containers/SelectMethod/redux/saga';
/** Persist state */
import { loadState, saveState } from '../utils/commons/localstorage';

const sagaMiddleware = createSagaMiddleware();
const persistedState = loadState();

const store = createStore(
  reducer,
  persistedState,
  applyMiddleware(sagaMiddleware),
);

store.subscribe(() => saveState(store.getState()));

sagaMiddleware.run(loginSaga);
sagaMiddleware.run(verifySaga);
sagaMiddleware.run(reSendSaga);
sagaMiddleware.run(homeSaga);
sagaMiddleware.run(creditDetailSaga);
sagaMiddleware.run(selectMethodSaga);

export default store;