import {
  SET_ERROR,
  SET_LOADING,
  SET_LOCALE,
  SET_DOCUMENT,
  SET_PHONE,
  SET_ACCESS_TOKEN,
  SET_USERNAME,
  SET_CREDITS,
  UNSET_SESSION,
  SET_PAYMENT_PLAN,
} from './actionTypes'

export const setError = error => ({
  type: SET_ERROR,
  payload: error
});

export const setLoading = isLoading => ({
  type: SET_LOADING,
  payload: isLoading
});

export const setLocale = locale => ({
  type: SET_LOCALE,
  payload: locale
});

export const setDocument = document => ({
  type: SET_DOCUMENT,
  payload: document
});

export const setPhone = document => ({
  type: SET_PHONE,
  payload: document
});

export const setAccessToken = accessToken => ({
  type: SET_ACCESS_TOKEN,
  payload: { accessToken }
});

export const setUsername = username => ({
  type: SET_USERNAME,
  payload: { username }
});

export const setCredits = credits => ({
  type: SET_CREDITS,
  payload: credits
});

export const setPaymentPlan = paymentPlan => ({
  type: SET_PAYMENT_PLAN,
  payload: paymentPlan
});

export const unsetSession = () => ({ type: UNSET_SESSION });
