/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Login/messages.js
 * @description Login component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Login';

export default defineMessages({
  loginButton: { id: 'Buttons.Login' },
  personalIdentification: { id: `${parent}.personalIdentification` },
  numberPhone: { id: `${parent}.numberPhone` },
  quickPayment: { id: `${parent}.quickPayment` },
});
