/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Verify/messages.js
 * @description Verify component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Verify';

export default defineMessages({
  verifyButton: { id: 'Buttons.VerifyButton' },
  verifyCode: { id: `${parent}.verifyCode` },
  info: { id: `${parent}.info` },
  retry: { id: `${parent}.retry` },
  copyrightName: { id: `${parent}.copyrightName` },
  copyrightReserved: { id: `${parent}.copyrightReserved` },
  goBackLoginButton: { id: `${parent}.goBackLoginButton` },
});