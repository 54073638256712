/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Resend/actionTypes.js
 * @description action types for Resend component
 */

export const RESEND = 'RESEND';
