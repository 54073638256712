/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Verify/actions.js
 * @description actions for Verify component
 */

import { RESEND } from './actionTypes';

export const reSend = (payload, callback) => ({
  type: RESEND,
  payload,
  callback
});