/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Login/actions.js
 * @description actions for Login component
 */

import { GET_QUICK_PAYMENT, CREATE_ORDER } from './actionTypes';

export const getData = (payload, callback) => ({
  type: GET_QUICK_PAYMENT,
  payload,
  callback,
});

export const createOrder = (payload, callback) => ({
  type: CREATE_ORDER,
  payload,
  callback,
});
