/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Form/config.js
 * @description Form helpers
 */

/** Messages */
import messages from './messages';

/**
 * required function
 * @param { string | number } value - value in field
 */
export const required = value => (value ? undefined : messages.required);

/**
 * mustBeNumber function
 * @param { string | number } value - value in field
 */
export const mustBeNumber = value => (isNaN(value) ? messages.mustBeNumber : undefined);

/**
 * minValue function
 * @param { string | number } value - value in field
 */
export const minValue = min => value => isNaN(value) || value >= min
    ? undefined
    : { ...messages.minValue, values: { min } };

/**
 * composeValidators
 * @param { any[] } validators - validator in form
 */
export const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

export const INPUT_TEXT = 'text';
export const INPUT_EMAIL = 'email';
export const INPUT_PASSWORD = 'password';
export const INPUT_NUMBER = 'number';
export const INPUT_DATE = 'date';
export const SELECT = 'select';
export const BUTTON = 'button';
export const SUBMIT = 'submit';
export const COMPONENT = 'component';
