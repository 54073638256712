/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file MethodPSE/messages.js
 * @description MethodPSE component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';
/** Other messages */
import selectMessages from '../SelectMethod/messages';
import cashMessages from '../MethodCash/messages';

const parent = 'MethodPSE';

export default defineMessages({
  totalPayment: { ...cashMessages.totalPayment },
  methodPSE: { ...selectMessages.methodPSE },
  typePerson: { id: `${parent}.typePerson` },
  bankLabel: { id: `${parent}.bankLabel` },
  buttonPayment: { id: `${parent}.buttonPayment` },
});
