/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file MethodCash/messages.js
 * @description MethodCash component
 */

/** Dependencies */
import { defineMessages } from 'react-intl';
/** Other messages */
import messages from '../SelectMethod/messages';

const parent = 'MethodCash';

export default defineMessages({
  dateLimit: { id: `${parent}.dateLimit` },
  totalPayment: { id: `${parent}.totalPayment` },
  refCode: { id: `${parent}.refCode` },
  agreementCode: { id: `${parent}.agreementCode` },
  help0: { id: `${parent}.help0` },
  help1: { id: `${parent}.help1` },
  methodCashDesc: { ...messages.methodCashDesc },
});
