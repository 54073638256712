/** Dependencies */
import { defineMessages } from "react-intl";

const parent = "NotRegistered";

export default defineMessages({
  loginButton: { id: "Buttons.Login" },
  not_registered: { id: "General.401" },
  information: { id: `${parent}.information` },
});
