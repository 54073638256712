/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file HeadersPayment/index.js
 * @description HeadersPayment container
 */

/** Dependencies */
import React from "react";
import { FormattedMessage } from "react-intl";

const HeadersPayment = ({ values }) => (
  <>
    {values.map(({ label, value }, index) => (
      <div
        key={`header-${String(value)}-${index}`}
        className="col-12 col-md-8 text-center mx-auto mb-1 px-4"
      >
        <span>
          <strong>
          <FormattedMessage {...label} />
          </strong>
        </span>
        <br />
        <strong className="fs-4 mx-auto text-primary">{value}</strong>
      </div>
    ))}
  </>
);

export default HeadersPayment;
