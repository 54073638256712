/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file CreditDetail/view.jsx
 * @description CreditDetail component
 */

/** Dependencies */
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
/** Components */
import DefaultLayout from '../../components/Layout/Default';
import Fee from '../../components/Fee';
import PaymentForm from './elements/PaymentForm/view';
/** Helpers */
import { formatCurrency } from '../../utils/commons/helpers';
/** Messages */
import messages from './messages';
/** Routes */
import { PATH_HOME } from '../../config/Routes';
/** Constants */
import {
  STATUS_FILTER_ACTIVE_OR_DELAY,
  STATUS_FILTER_PAID,
  STATUS_FEE_PAID,
} from './constants';
/** Styles */
import './styles.scss';

export class CreditDetail extends Component {
  /**
   * Initialize CreditDetail
   * @param { object } props - Component properties
   */
  constructor(props) {
    super(props);
    this.state = {
      fees: [],
      filter: STATUS_FILTER_ACTIVE_OR_DELAY,
    };

    this.alterFeeValue = this.alterFeeValue.bind(this);
    this.setStatusFilterPaid = this.setStatusFilterPaid.bind(this);
    this.setStatusFilterActive = this.setStatusFilterActive.bind(this);
  }

  /**
   * React Lifecycle method, call when mount component
   */
  componentDidMount() {
    this.getPaymentPlan();
  }

  /**
   * React Lifecycle method, call when component is update
   * @param { object } prevProp - Preview component properties
   */
  componentDidUpdate(prevProps) {
    this.whenAllFeesArePaid(prevProps)
  }

  /**
   * React Lifecycle method, call when unmount component
   */
  componentWillUnmount() {
    this.clearPaymentPlan();
  }

  /**
   * React Lifecycle method, render CreditDetail Component
   * @return { Component } CreditDetail
   */
  render() {
    const { globalActions, history, loading, error, document } = this.props;
    const { setLocale, setError } = globalActions;

    const creditsActive = this.getCreditsActive().length;

    return (
      <>
        <DefaultLayout
          history={history}
          loading={loading}
          error={error}
          username={formatCurrency(Number(document.split('.').join('')))}
          setLocale={setLocale}
          setError={setError}
        >
          <div className='row mt-2'>
            {this.renderTitle()}
            {this.renderAllPlan()}
          </div>
        </DefaultLayout>
        {creditsActive > 0 && this.renderPaymentForm()}
      </>
    );
  }

  /**
   * renderTitle
   */
  renderTitle() {
    const creditId = this.getPaymentPlanId();

    return (
      <>
        <div className='col-lg-7 col-md-8 col-sm-10 col-12 mx-auto d-flex justify-content-between'>
          <Link to={PATH_HOME}>
            <i className='fas fa-caret-left me-2' />
            <FormattedMessage {...messages.backButton} />
          </Link>
        </div>

        <div className='col-lg-7 col-md-8 col-sm-10 col-12 mx-auto d-flex justify-content-between'>
          <span className='fs-2 fw-bold'>
            <FormattedMessage {...messages.paymentPlan} />
          </span>
        </div>

        <div className='col-lg-7 col-md-8 col-sm-10 col-12 mx-auto d-flex justify-content-between fs-2 fw-bold'>
          <span>
            <FormattedMessage {...messages.creditLabel} />
          </span>
          <span>
            {creditId}
          </span>
        </div>
      </>
    );
  }

  /**
   * drawFee
   * @param { object } fee 
   * @param { number } index 
   * @param { number } creditId
   */
  drawFee(fee, index, creditId) {
    const { fees } = this.state;
    const { feeId: id } = fee;

    return (
      <Fee
        key={`${id}-${index}`}
        {...fee}
        index={index}
        creditId={creditId}
        checked={fees.includes(id)}
        onClick={(isChecked, feeId) => this.addFeeValueToPayment(isChecked, feeId)}
      />
    );
  }

  /**
   * drawSelectAll
   * @param { number } activeCreditsLength
   */
  drawSelectAll(activeCreditsLength) {
    const { paymentPlan } = this.props;
    const { filter, fees } = this.state;

    const checked = fees.length === paymentPlan.length || fees.length === activeCreditsLength;

    if (filter !== STATUS_FILTER_PAID && activeCreditsLength > 0) {
      return (
        <Fee
          selectAll
          checked={checked}
          onClick={isChecked => this.selectAllPayments(isChecked)}
        />
      )
    }

    return null;
  }

  /**
   * drawFilterButtonGroup
   * @param { number } activeCreditsLength
   */
  drawFilterButtonGroup(activeCreditsLength) {
    const { filter: filterState } = this.state;

    const setActiveFilterButton = (
      <button
        className={`btn btn-${filterState !== STATUS_FILTER_ACTIVE_OR_DELAY ? 'light' : 'primary'}`}
        onClick={this.setStatusFilterActive}
        disabled={activeCreditsLength === 0}
      >
        <FormattedMessage {...messages.feesActivesOrDelay} />
      </button>
    );

    return (
      <div className='btn-group btn-group-sm'>
        {activeCreditsLength > 0 && setActiveFilterButton}
        <button
          className={`btn btn-${filterState !== STATUS_FILTER_PAID ? 'light' : 'primary'}`}
          onClick={this.setStatusFilterPaid}
        >
          <FormattedMessage {...messages.feesPaid} />
        </button>
      </div>
    );
  }

  /**
   * renderAllPlan
   */
  renderAllPlan() {
    const { paymentPlan } = this.props;
    const { filter: filterState } = this.state;
    const creditId = this.getPaymentPlanId();

    if (!paymentPlan) return null;

    const creditsPaid = this.getCreditsPaid();
    const creditsActive = this.getCreditsActive();

    const arrayFees = filterState === STATUS_FILTER_PAID
      ? creditsPaid
      : creditsActive;

    return (
      <div
        className='col-lg-7 col-md-8 col-sm-10 col-12 mx-auto'
        style={{ marginBottom: `calc(${168}px + 1rem)` }}
      >
        {this.drawFilterButtonGroup(creditsActive.length)}

        {this.drawSelectAll(creditsActive.length)}

        {arrayFees.map((fee, index) => this.drawFee(fee, index, creditId))}
      </div>
    );
  }

  /**
   * renderPaymentForm
   */
  renderPaymentForm() {
    const { filter: filterState } = this.state;

    if (filterState !== STATUS_FILTER_ACTIVE_OR_DELAY) return null;

    const payment = this.getPayment();

    return (
      <div className='full-width position-fixed bottom-0 bg-white border-top'>
        <div className='container'>
          <div className='row'>
            <PaymentForm
              payment={payment}
              onChange={() => false}
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * selectAllPayments
   * @param { boolean } isChecked
   */
  selectAllPayments(isChecked) {
    const { paymentPlan } = this.props;

    if (!paymentPlan) return;

    if (isChecked) {
      const fees = paymentPlan.map(({ feeId }) => feeId);
      this.setState({ fees });
    } else {
      this.setState({ fees: [] });
    }
  }

  /**
   * addFeeValueToPayment
   * @param { boolean } isChecked 
   * @param { number } feeId
   */
  addFeeValueToPayment(isChecked, feeId) {
    const { fees } = this.state;

    if (isChecked) {
      fees.push(feeId);
      this.setState({ fees });
    } else {
      const index = fees.indexOf(feeId);
      fees.splice(index, 1);
      this.setState({ fees });
    }
  }

  /**
   * alterFeeValue
   * @param { object } fee
   * @param { number } index
   */
  alterFeeValue(fee, index) {
    const valuePaid = this.getValuePaid();

    const { feeValue } = fee;
    if (index === 0) return { ...fee, valuePaid, feeValue: feeValue - valuePaid, rawFeeValue: feeValue };
    return fee;
  }

  /**
   * getCreditsPaid
   */
  getCreditsPaid() {
    const { paymentPlan } = this.props;

    if (!paymentPlan) return [];

    return paymentPlan
      .filter(({ statusFeeId }) => statusFeeId === STATUS_FEE_PAID)
      .sort((firstEl, secondEl) => {
        const fpDatePayment = new Date(firstEl.datePayment).getTime();
        const spDatePayment = new Date(secondEl.datePayment).getTime();

        if (fpDatePayment > spDatePayment) return -1;
        if (fpDatePayment < spDatePayment) return 1;
        return 0;
      });
  }

  /**
   * getCreditsActive
   */
  getCreditsActive() {
    const { paymentPlan } = this.props;

    if (!paymentPlan) return [];

    return paymentPlan
      .filter(({ statusFeeId }) => statusFeeId !== STATUS_FEE_PAID)
      .map(this.alterFeeValue);
  }

  /**
   * getPayment
   */
  getPayment() {
    const { paymentPlan } = this.props;

    if (!paymentPlan) return 0;

    const { fees } = this.state;
    const filtered = paymentPlan
      .filter(({ statusFeeId }) => statusFeeId !== STATUS_FEE_PAID)
      .map(this.alterFeeValue)
      .filter(fee => fees.includes(fee.feeId))
      .map(({ feeValue }) => feeValue);

    if (filtered.length === 0) return 0;

    const reduced = filtered
      .reduce((acc, feeValue) => acc += feeValue);

    return reduced;
  }

  /**
   * getEncodedValues
   */
  getEncodedValues() {
    const {
      match: {
        params: { id: encodedUuid },
      },
    } = this.props;
    return encodedUuid.split('-');
  }

  /**
   * getPaymentPlanId
   */
  getPaymentPlanId() {
    const [encodedId] = this.getEncodedValues();
    const id = atob(encodedId);

    return id;
  }

  /**
   * getValuePaid
   */
  getValuePaid() {
    // eslint-disable-next-line no-unused-vars
    const [_, encodedValuePaid] = this.getEncodedValues();
    const valuePaid = atob(encodedValuePaid);

    return Number(valuePaid);
  }

  /**
   * getPaymentPlan
   */
  getPaymentPlan() {
    const {
      actions: { getPaymentPlan },
    } = this.props;
    const id = this.getPaymentPlanId();
    getPaymentPlan(id);
  }

  /**
   * clearPaymentPlan
   */
  clearPaymentPlan() {
    const { globalActions: { setPaymentPlan } } = this.props;
    setPaymentPlan(undefined);
  }

  /**
   * setStatusFilterPaid
   */
  setStatusFilterPaid() {
    this.setState({ filter: STATUS_FILTER_PAID });
  }

  /**
   * setStatusFilterActive
   */
  setStatusFilterActive() {
    this.setState({ filter: STATUS_FILTER_ACTIVE_OR_DELAY })
  }

  /**
   * whenAllFeesArePaid
   * @param { object } prevProps 
   */
  whenAllFeesArePaid(prevProps) {
    const { paymentPlan } = this.props;
    const { paymentPlan: prevPaymentPlan } = prevProps;
    const creditsActive = this.getCreditsActive().length;
    if (paymentPlan !== prevPaymentPlan && creditsActive === 0) this.setStatusFilterPaid();
  }
}

export default injectIntl(CreditDetail);