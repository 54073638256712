/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file view.js
 * @description Login Container
 */

/** Dependencies */
import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
/** Components */
import FormBuilder, {
  required,
  INPUT_TEXT,
  INPUT_PASSWORD,
  SUBMIT,
} from '../../components/Form';
import AuthLayout from '../../components/Layout/Auth';
import Button from '../../components/Form/elements/Button';
/** Routes */
import { PATH_AUTH_LOGIN, PATH_AUTH_RESEND, PATH_HOME } from '../../config/Routes';
/** Messages */
import messages from './messages';

export class Verify extends Component {
  /**
   * React Lifecycle method, render Login Container
   * @return { Component } Login
   */
  render() {
    const { globalActions, loading, error } = this.props;
    const { setError } = globalActions;
    return (
      <AuthLayout
        loading={loading}
        error={error}
        setError={setError}
      >
        <FormBuilder
          onSubmit={this.login(this.props)}
          fields={this.fields()}
        />
        {this.renderPhone()}
        {this.renderRetryButton()}
        {this.renderGoBackLoginButton()}
      </AuthLayout>
    );
  }

  /**
   * renderPhone its a helper method to return normalized number phone 
   */
  renderPhone() {
    const { phone: mobilePhone } = this.props;

    const phone = `${mobilePhone}`
      .split('')
      .reverse()
      .map((number, index) => index > 1 ? 'x' : number)
      .reverse()
      .join('');

    return (
      <p className='text-center'>
        <FormattedMessage {...messages.info} values={{ phone }} />
      </p>
    );
  }

  /**
   * renderRetryButton
   */
  renderRetryButton() {
    const { intl: { formatMessage } } = this.props;

    return (
      <Button
        className='btn-link'
        onClick={() => this.goToRetry()}
        label={formatMessage(messages.retry)}
      />
    );
  }

  /**
   * renderGoBackLoginButton
   */
  renderGoBackLoginButton() {
    const { intl: { formatMessage } } = this.props;

    return (
      <Button
        className='btn-link'
        onClick={() => this.goToSignIn()}
        label={formatMessage(messages.goBackLoginButton)}
      />
    );
  }

  /**
   * fields its a helper function to render fields in forms
   */
  fields() {
    const {
      intl: { formatMessage },
      document
    } = this.props;

    return [
      {
        keyField: 'usernameOrEmail',
        type: INPUT_TEXT,
        validators: [required],
        placeholder: formatMessage(messages.verifyCode),
        value: document,
        hidden: true,
      },
      {
        keyField: 'password',
        type: INPUT_PASSWORD,
        className: 'text-center',
        validators: [required],
        placeholder: formatMessage(messages.verifyCode),
        inputmode: 'tel',
        maxlength: 6
      },
      {
        keyField: 'submit',
        type: SUBMIT,
        label: formatMessage(messages.verifyButton),
        className: 'btn-primary'
      }
    ];
  }

  /** 
   * goToRetry its a helper function to navigate to /auth/resend
   */
  goToRetry() {
    const { history } = this.props;
    history.push(PATH_AUTH_RESEND);
  }

  /** 
   * goToSignIn its a helper function to navigate to /auth/login
   */
  goToSignIn() {
    const { history } = this.props;
    history.push(PATH_AUTH_LOGIN);
  }

  /**
   * login
   * @param { object } props 
   */  
  login = props => values => {
    const { history, actions: { setLogin }, } = props;
    const redirect = () => history.push(PATH_HOME);
    setLogin(values, redirect);
  }
}

export default injectIntl(Verify);