/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file MethodPSE/index.js
 * @description MethodPSE Container
 */

/** Dependencies */
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';

/** View */
import MethodPSE from './view';

/** Redux */
import * as globalActions from '../../store/actions';

/**
 * Get Actions to components
 * @param { function } dispatch - Run action to execute in redux
 */
export const mapDispatchToProps = dispatch => ({
  globalActions: bindActionCreators(globalActions, dispatch),
});

/**
 * Get props to components
 * @param { function } state - Redux state
 */
const mapStateToProps = state => ({
  loading: state.loading,
  error: state.error,
});

/**
 * Prepare actions and data to Redux
 */
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MethodPSE);