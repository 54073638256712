/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Verify/actions.js
 * @description actions for Verify component
 */

import { SET_LOGIN } from './actionTypes';

export const setLogin = (payload, callback) => ({
  type: SET_LOGIN,
  payload,
  callback
});