/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Form/messages.js
 */

/** Dependencies */
import { defineMessages } from 'react-intl';

const parent = 'Form';

export default defineMessages({
  required: { id: `${parent}.required` },
  mustBeNumber: { id: `${parent}.mustBeNumber` },
  minValue: { id: `${parent}.minValue` }
});