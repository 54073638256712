/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file redux/saga.js
 */

/** Dependencies */
import { takeLatest } from 'redux-saga/effects';
/** Services */
import * as creditsService from '../../../services/credits';
/** Constants */
import { GET_CREDITS } from './actionTypes';

export default function* homeSaga() {
  yield takeLatest(GET_CREDITS, creditsService.creditByDocument);
}