/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file redux/actions.jsx
 * @description actions for Home component
 */

import { GET_CREDITS } from './actionTypes';

export const getCredits = document => ({
  type: GET_CREDITS,
  payload: document
});