/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Select/index.jsx
 * @description Select component
 */

/** Dependencies */
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

export class Select extends Component {
  /**
   * React Lifecycle method, render Select Container
   * @return { Component } Select
   */
  render() {
    const { keyField, options, placeholder } = this.props;
    return (
      <div className='mb-3'>
        <Field
          name={keyField}
          component='select'
          className='form-control'
        >
          <option>-- {placeholder} --</option>
          {options.map(({ label, value }, index) => (
            <option key={`${value}-${index}`} value={value}>{label}</option>
          ))}
        </Field>
      </div>
    );
  }
}

Select.propTypes = {
  keyField: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.object.isRequired
  ).isRequired,
  placeholder: PropTypes.string
};

export default Select;
