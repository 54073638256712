/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file redux/actions.jsx
 * @description actions for Home component
 */

import { GET_PAYMENT_PLAN } from './actionTypes';

export const getPaymentPlan = creditId => ({
  type: GET_PAYMENT_PLAN,
  payload: creditId
});