/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Copyright/index.jsx
 * @description Copyright component
 */

/** Dependencies */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
/** Messages */
import messages from './messages';
/** Styles */
import './styles.scss';

const Copyright = props => (
  <div className={`text-copyright ${props.className}`}>
    <span>
      <FormattedMessage {...messages.copyrightName} />
    </span>
    <br />
    <span>
      <FormattedMessage {...messages.copyrightReserved} />
    </span>
  </div>
);

Copyright.propTypes = {
  className: PropTypes.string
};

Copyright.defaultProps = {
  className: 'align-self-end text-center col-12'
};

export default Copyright;