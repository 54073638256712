/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author jotacemarin
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
/** Routes */
import { PATH_AUTH_LOGIN } from "../../config/Routes";
/** Redux */
import * as globalActions from "../../store/actions";
/** Messages */
import messages from "./messages";
/** Constants */

/**
 * Get Actions to components
 * @param { function } dispatch - Run action to execute in redux
 */
export const mapDispatchToProps = (dispatch) => ({
  globalActions: bindActionCreators(globalActions, dispatch),
});

/**
 * Get props to components
 * @param { function } state - Redux state
 */
const mapStateToProps = (state) => ({
  loading: state.loading,
});

/**
 * Prepare actions and data to Redux
 */
const withConnect = connect(mapStateToProps, mapDispatchToProps);

const Unauthorized = (props) => {
  const {
    globalActions: { unsetSession },
  } = props;

  const { loginButton, unauthorized, information } = messages;

  return (
    <div className="d-flex flex-column justify-content-center align-items-center full-height">
      <h2>
        <FormattedMessage {...unauthorized} />
      </h2>
      <div className="text-center mx-2">
        <span className="me-1">
          <FormattedMessage {...information} />
        </span>
        <br className="d-inline d-sm-none" />
        <Link to={PATH_AUTH_LOGIN} onClick={() => unsetSession()}>
          <FormattedMessage {...loginButton} />
        </Link>
      </div>
    </div>
  );
};

export default compose(withConnect)(Unauthorized);
